import {
  CodeIcon,
  SecurityIcon,
  TfDataIcon,
  CloudIcon,
  MonitorIcon,
  ChartBarLineIcon,
  TfArtificialIntelligenceIcon,
  IlxIcon,
  BriefcaseIcon,
} from '@pluralsight/icons';

import type { ElementType } from 'react';

export interface ICategory {
  id: number;
  icon: ElementType;
  title: string;
}
// TODO: Since BE part for categories data is not ready, it's temporary.
export const categories: ICategory[] = [
  {
    id: 1,
    icon: TfArtificialIntelligenceIcon,
    title: 'Artificial Intelligence',
  },
  {
    id: 2,
    icon: CodeIcon,
    title: 'Software Development',
  },
  {
    id: 3,
    icon: ChartBarLineIcon,
    title: 'Software Architecture',
  },
  {
    id: 4,
    icon: CloudIcon,
    title: 'Cloud',
  },
  {
    id: 5,
    icon: TfDataIcon,
    title: 'Data',
  },
  {
    id: 6,
    icon: SecurityIcon,
    title: 'Security',
  },
  {
    id: 7,
    icon: MonitorIcon,
    title: 'IT Ops',
  },
  {
    id: 8,
    icon: IlxIcon,
    title: 'Product & UX',
  },
  {
    id: 9,
    icon: BriefcaseIcon,
    title: 'Business',
  },
];
