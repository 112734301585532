import { useMutation } from '@tanstack/react-query';

import { createAccount } from '@ps-fe-authentication/api/services/user-service';
import { acceptInvitation } from '@ps-fe-authentication/api/services/plans-service';

import type { UseMutationOptions } from '@tanstack/react-query';
import type {
  AcceptInvitationRequest,
  CreateAccountData,
} from '@ps-fe-authentication/types';

interface CreateAccountMutation {
  data: CreateAccountData;
  psId: string;
}

export const useCreateAccountByIdMutation = (
  options?: UseMutationOptions<unknown, Error, CreateAccountMutation>,
) => {
  return useMutation<unknown, Error, CreateAccountMutation>({
    mutationFn: async ({ data, psId }) => {
      return await createAccount({ data, psId });
    },
    ...options,
  });
};

export const useAcceptInvitationMutation = (
  options?: UseMutationOptions<string | null, Error, AcceptInvitationRequest>,
) => {
  return useMutation<string | null, Error, AcceptInvitationRequest>({
    mutationFn: async (request) => {
      return await acceptInvitation(request);
    },
    ...options,
  });
};
