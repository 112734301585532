import { useEffect, useMemo, useRef, useState } from 'react';
import {
  FlexContainer,
  FormControlProvider,
  Input,
  Label,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@pluralsight/react-ng';
import { SearchIcon } from '@pluralsight/icons';
import Fuse from 'fuse.js';

import { useInterestsQuery } from '@ps-fe-authentication/api/queries';
import { useTranslations } from '@ps-fe-authentication/contexts/TranslationsContext';

import SearchResultItem from './search-result-item';

import './search-interests.scss';

import type { IFuseOptions } from 'fuse.js';
import type { ChangeEvent } from 'react';
import type { IInterest } from '@ps-fe-authentication/types/interests';

// At what point does the match algorithm give up.
// A threshold of 0.0 requires a perfect match (of both letters and location), a threshold of 1.0 would match anything.
const threshold = 0.3;
const fuseOptions: IFuseOptions<IInterest> = {
  keys: ['name', 'categoryName'],
  threshold,
};

const SearchInterests = () => {
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { data: interests } = useInterestsQuery();

  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslations();

  const fuse = useMemo(() => new Fuse(interests, fuseOptions), [interests]);

  const filteredInterests = useMemo(() => {
    if (!query.trim()) return interests;

    const results = fuse.search(query);

    return results.map((result) => result.item);
  }, [query, fuse, interests]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setIsOpen(e.target.value.trim().length > 0);
  };

  useEffect(() => {
    // Shift focus back on the input.
    if (isOpen && inputRef.current) {
      setTimeout(() => {
        inputRef?.current?.focus();
      });
    }
  }, [isOpen]);

  return (
    <Popover
      open={isOpen}
      onOpenChange={setIsOpen}
      placement="bottom"
      ref={null}
    >
      <PopoverTrigger aschild onClick={() => undefined}>
        <FlexContainer
          className="interests__search search"
          aria-haspopup={undefined}
          aria-expanded={undefined}
        >
          <FormControlProvider>
            <Label htmlFor="search-interests" className="sr-only">
              {t('interests.steps.interests.searchInput.label')}
            </Label>
            <Input
              id="search-interests"
              name="search-interests"
              ref={inputRef}
              size="md"
              type="search"
              startIcon={<SearchIcon className="search__icon" />}
              placeholder={t(
                'interests.steps.interests.searchInput.placeholder',
              )}
              className="search__input"
              value={query}
              onChange={handleChange}
              onFocus={handleChange}
            />
          </FormControlProvider>
        </FlexContainer>
      </PopoverTrigger>
      <PopoverContent
        className="pando-popover__root search-popover"
        role={undefined}
      >
        <PopoverBody>
          {filteredInterests.length ? (
            <ul className="search-popover__list">
              {filteredInterests.map((interest) => (
                <li key={interest.id}>
                  <SearchResultItem interest={interest} />
                </li>
              ))}
            </ul>
          ) : (
            <p>{t('interests.steps.interests.searchInput.noResultsFound')}</p>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default SearchInterests;
