export enum IssueType {
  ManualLicenseAssignment = 'manual-license-assignment',
  NoLicenseError = 'no-license-error',
}

export interface IssueContent {
  heading: string;
  description: string;
  actionLabel?: string;
}

export const ISSUE_CONTENT_KEYS: Record<IssueType, IssueContent> = {
  [IssueType.ManualLicenseAssignment]: {
    heading: 'issue.manualLicenseAssignment.heading',
    description: 'issue.manualLicenseAssignment.description',
    actionLabel: 'issue.manualLicenseAssignment.action',
  },
  [IssueType.NoLicenseError]: {
    heading: 'issue.noLicenseError.heading',
    description: 'issue.noLicenseError.description',
    actionLabel: 'issue.noLicenseError.action',
  },
};
