import {
  Button,
  FieldMessage,
  FlexContainer,
  FormControlProvider,
  Input,
  Label,
} from '@pluralsight/react-ng';
import { EnvelopeIcon } from '@pluralsight/icons';
import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import './login-form.scss';
import { validationUtils } from '@ps-fe-authentication/shared/utils';
import {
  buildAuthUrl,
  getAuthConfig,
  getRedirectUrl,
} from '@ps-fe-authentication/utils/login';
import { useTranslations } from '@ps-fe-authentication/contexts/TranslationsContext';
import {
  initialFormState,
  initialFromFields,
  KeyboardEventLoginPage,
  LoginOptions,
} from '@ps-fe-authentication/constants/login-form';
import { useAppConfig } from '@ps-fe-authentication/contexts/AppConfigContext';

import type {
  AccountType,
  ProvisionRegion,
  AuthForm,
} from '@ps-fe-authentication/types';

const LoginForm = () => {
  const { t } = useTranslations();
  const [isBusiness, setIsBusiness] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(false);
  const [regionOptions, setRegionOptions] = useState<
    { region: string; displayName: string }[]
  >([]);
  const [region, setRegion] = useState<string | null>(null);
  const [selectedRegionAuth, setSelectedRegionAuth] = useState<string | null>(
    null,
  );
  const [isTraveler, setIsTraveler] = useState(false);
  const [formState, setFormState] = useState(initialFormState);
  const [formFields, setFormFields] = useState<AuthForm>(initialFromFields);
  const [selectedTab, setSelectedTab] = useState<LoginOptions>(
    LoginOptions.Individual,
  );
  const tabRefs = useRef<HTMLButtonElement[]>([]);

  const config = useAppConfig();

  type FormState = typeof formState;

  type FieldName = keyof FormState;

  useEffect(() => {
    const accountType = Cookies.get('accountType') as AccountType;

    if (accountType) {
      const regions = config.COMMON.TENANTS[accountType];
      const displayNames = Object.values(regions).map((region) => ({
        region: region.REGION,
        displayName: region.DISPLAY_NAME,
      }));

      setRegionOptions(displayNames);
    } else {
      console.error('AccountType is undefined');
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const selectRegion = urlParams.get('selectRegion') || false;
    const username = urlParams.get('username') || '';
    const regionAuth = urlParams.get('selectedRegion') || '';
    const provisionedRegion = Cookies.get(
      'provisionedRegion',
    ) as ProvisionRegion;
    const accountType = Cookies.get('accountType') as AccountType;

    if (accountType && provisionedRegion) {
      const selectedTenant =
        config.COMMON.TENANTS[accountType][provisionedRegion];
      const displayName = selectedTenant.DISPLAY_NAME;

      setRegion(displayName);
    } else {
      setRegion(provisionedRegion || null);
    }

    setFormFields((prev) => ({ ...prev, username }));
    setSelectedRegionAuth(regionAuth);

    if (accountType) {
      setTenantDetails(accountType);
      setIsBusiness(accountType === 'B2B');
    }

    setSelectedRegion(selectRegion === 'true');
    setIsTraveler(selectRegion === 'true');

    if (provisionedRegion) {
      setRegionOptions((prevOptions) =>
        prevOptions.map((option) =>
          option.region === provisionedRegion
            ? { ...option, selected: true }
            : option,
        ),
      );
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    if (selectedTab === LoginOptions.Individual) {
      tabRefs.current[0]?.focus();
      handleIndividualClick();

      return;
    }

    if (selectedTab === LoginOptions.Business) {
      tabRefs.current[1]?.focus();
      handleBusinessClick();

      return;
    }
  }, [selectedTab]);

  // const handleLoginSSO = () => {
  //   console.log('Login with SSO');
  // };

  const handleContinue = () => {
    try {
      const authConfig = getAuthConfig();

      if (!authConfig) return;

      const authorizationParams = {
        login_hint: formFields.username,
        region,
        selectedRegion: Cookies.get('provisionedRegion'),
        isTraveler,
      };

      const redirectUrl = getRedirectUrl(
        config.AUTHENTICATION.AUTH0.AUTH_CALLBACK.BASE_URL,
        config.AUTHENTICATION.AUTH0.AUTH_CALLBACK.PATH,
      );
      const url = buildAuthUrl(authConfig, redirectUrl, authorizationParams);

      url.searchParams.set(
        'organization',
        config.AUTHENTICATION.AUTH0.ORGANIZATION ||
          config.AUTHENTICATION.AUTH0.B2C_ORGANIZATION_ID,
      );

      window.location.href = url.toString();
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const handleContinueToOrg = () => {
    try {
      const authConfig = getAuthConfig();

      if (!authConfig) return;

      const redirectUrl = getRedirectUrl(
        config.AUTHENTICATION.AUTH0.AUTH_CALLBACK.BASE_URL,
        config.AUTHENTICATION.AUTH0.AUTH_CALLBACK.PATH,
      );

      const url = buildAuthUrl(authConfig, redirectUrl);

      if (config.AUTHENTICATION.AUTH0.ORGANIZATION) {
        url.searchParams.set(
          'organization',
          config.AUTHENTICATION.AUTH0.ORGANIZATION,
        );
      }

      window.location.href = url.toString();
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const setTenantDetails = (accountType: AccountType) => {
    const region = Cookies.get('provisionedRegion') as ProvisionRegion;

    if (region && accountType) {
      const selectedTenant = config.COMMON.TENANTS[accountType][region];

      Cookies.set('accountType', accountType, { expires: 1 });
      Cookies.set('tenantDetails', JSON.stringify(selectedTenant), {
        expires: 1,
      });

      const regions = config.COMMON.TENANTS[accountType];
      const displayNames = Object.values(regions).map((region) => ({
        region: region.REGION,
        displayName: region.DISPLAY_NAME,
      }));

      setRegionOptions(displayNames);
    } else {
      console.error('Region or accountType is undefined');
    }
  };

  const handleIndividualClick = useCallback(() => {
    setTenantDetails('B2C');
    setIsBusiness(false);
  }, []);

  const handleBusinessClick = useCallback(() => {
    setTenantDetails('B2B');
    setIsBusiness(true);
  }, []);

  const handleRegionChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const regionSelected = event.target.value as ProvisionRegion;
      const accountType = Cookies.get('accountType') as AccountType;

      if (accountType && regionSelected) {
        const selectedTenant =
          config.COMMON.TENANTS[accountType][regionSelected];
        const displayName = selectedTenant.DISPLAY_NAME;

        setSelectedRegionAuth(regionSelected);
        setRegion(displayName);
        Cookies.set('provisionedRegion', regionSelected, { expires: 1 });
        Cookies.set('tenantDetails', JSON.stringify(selectedTenant), {
          expires: 1,
        });

        const url = new URL(window.location.href);

        url.searchParams.set('selectedRegion', regionSelected);
        window.history.pushState({}, '', url.toString());
      } else {
        console.error('AccountType or regionSelected is undefined');
      }
    },
    [],
  );

  const isValidField = (key: string): key is FieldName => key in formState;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (!isValidField(name)) return;

    setFormFields((prev) => ({ ...prev, [name]: value }));
    setFormState((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        invalid:
          typeof value === 'string' && !validationUtils.emailFormat(value),
      },
    }));
  };

  const isFormInvalid = () =>
    Object.values(formState).some((field) => field.invalid) ||
    formFields.username === '';

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (tabRefs.current.length === 0) return;

    if (
      event.key === KeyboardEventLoginPage.ArrowRight ||
      event.key === KeyboardEventLoginPage.ArrowLeft
    ) {
      event.preventDefault();
      setSelectedTab((prev) =>
        prev === LoginOptions.Individual
          ? LoginOptions.Business
          : LoginOptions.Individual,
      );
    }
  }, []);

  return (
    <FlexContainer
      direction="col"
      className="login-form"
      style={{
        width: '356px',
        margin: '120px 0px 252px',
        flexGrow: 0,
        flexShrink: 0,
      }}
    >
      <FlexContainer gap="15px" role="tablist">
        <Button
          role="tab"
          tabIndex={selectedTab === 'individual' ? 0 : -1}
          aria-selected={selectedTab === LoginOptions.Individual}
          palette="action"
          size="lg"
          usage={isBusiness ? 'outline' : 'filled'}
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => {
            setSelectedTab(LoginOptions.Individual);
            handleIndividualClick();
          }}
          ref={(element) => (tabRefs.current[0] = element as HTMLButtonElement)}
        >
          {t('form.optionIndividual')}
        </Button>
        <Button
          role="tab"
          tabIndex={selectedTab === 'business' ? 0 : -1}
          aria-selected={selectedTab === LoginOptions.Business}
          palette="action"
          size="lg"
          usage={isBusiness ? 'filled' : 'outline'}
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => {
            setSelectedTab(LoginOptions.Business);
            handleBusinessClick();
          }}
          ref={(element) => (tabRefs.current[1] = element as HTMLButtonElement)}
        >
          {t('form.optionBusiness')}
        </Button>
      </FlexContainer>
      <FormControlProvider {...formState.username}>
        <FlexContainer direction="col">
          <form
            style={{ marginTop: isBusiness ? '8px' : '40px' }}
            className="login-form__form"
            onSubmit={(e) => e.preventDefault()}
          >
            {!isBusiness && (
              <>
                <Label
                  htmlFor="username"
                  className={formState.username.invalid ? 'invalid' : ''}
                >
                  {t('form.email')}
                </Label>
                <Input
                  id="username"
                  name="username"
                  placeholder={t('form.emailPlaceholder')}
                  value={formFields.username}
                  onChange={handleInputChange}
                  startIcon={<EnvelopeIcon />}
                />
                {formState.username.invalid && (
                  <FieldMessage id="firstName:invalid">
                    {t('form.invalidEmail')}
                  </FieldMessage>
                )}
              </>
            )}
            {selectedRegion && (
              <FlexContainer direction="col" style={{ marginTop: '25px' }}>
                <label
                  htmlFor="username"
                  style={{
                    color: 'var(--neutral-text-300gst, #F3F3F8)',
                    fontFamily: 'var(--font-family-body, "PS TT Commons")',
                    fontSize: 'var(--font-size-sm, 14px)',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: '150%',
                  }}
                >
                  {t('form.registrationRegion')}
                </label>
                <div
                  style={{
                    position: 'relative',
                    padding: '10px var(--gap-md, 16px)',
                    height: 'var(--pando-button-height, 48px)',
                    borderRadius: '4px',
                    marginTop: '8px',
                    border: '1px solid var(--action-border-initial, #6855D9)',
                  }}
                >
                  <select
                    tabIndex={0}
                    name="region"
                    id="region"
                    value={selectedRegionAuth || ''}
                    style={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      gap: 'var(--gap-sm, 8px)',
                      alignSelf: 'stretch',
                      color: 'var(--neutral-text-100, #8B90C1)',
                      fontFamily: 'var(--font-family-body, "PS TT Commons")',
                      fontSize: 'var(--font-size-lg, 18px)',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '150%',
                    }}
                    onChange={handleRegionChange}
                  >
                    <option selected disabled>
                      Select a region
                    </option>
                    {regionOptions.map(({ region, displayName }) => (
                      <option key={region} value={region}>
                        {displayName}
                      </option>
                    ))}
                  </select>
                </div>
                <span
                  style={{
                    color: 'var(--neutral-text-200, #A5AACF)',
                    fontFamily: 'var(--font-family-body, "PS TT Commons")',
                    fontSize: 'var(--font-size-xs, 12px)',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '150%',
                    marginTop: '8px',
                  }}
                >
                  {t('form.registrationRegionMsj')}
                </span>
              </FlexContainer>
            )}
            <Button
              tabIndex={0}
              role="button"
              palette="action"
              size="lg"
              usage="filled"
              disabled={!isBusiness && isFormInvalid()}
              type="submit"
              style={{ marginTop: '32px', width: '100%', cursor: 'pointer' }}
              onClick={isBusiness ? handleContinueToOrg : handleContinue}
            >
              {isBusiness
                ? t('form.continueToOrganization')
                : t('form.continue')}
            </Button>
          </form>
        </FlexContainer>
      </FormControlProvider>
    </FlexContainer>
  );
};

export default LoginForm;
