import { useNavigate } from 'react-router-dom';

import {
  ISSUE_CONTENT_KEYS,
  IssueType,
} from '@ps-fe-authentication/constants/issue';
import { ROUTES } from '@ps-fe-authentication/constants';
import { useTranslations } from '@ps-fe-authentication/contexts/TranslationsContext';

import type { IssueContent } from '@ps-fe-authentication/constants/issue';

interface IUseIssueResult extends IssueContent {
  actionCallback?: () => void;
}

export const useIssue = (issueType?: IssueType): IUseIssueResult => {
  const { t } = useTranslations();
  const navigate = useNavigate();

  if (!issueType || !Object.values(IssueType).includes(issueType)) {
    return {
      heading: t('issue.default.heading'),
      description: 'issue.default.description',
    };
  }

  const { heading, description, actionLabel } = ISSUE_CONTENT_KEYS[issueType];

  const actionCallback = () => navigate(ROUTES.LOGIN);

  return {
    heading: t(heading),
    description,
    actionLabel: actionLabel ? t(actionLabel) : undefined,
    actionCallback,
  };
};
