import { useMemo, useState } from 'react';
import { FlexContainer, IconButton } from '@pluralsight/react-ng';
import { ChevronRightIcon, ChevronLeftIcon } from '@pluralsight/icons';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import cs from 'classnames';
import {
  PandoBreakpointsMd,
  PandoInkyBlueNeutralText300Dark,
} from '@pluralsight/design-tokens';
import { useTranslation } from 'react-i18next';

import './interest-category.scss';

import { useInterestsQuery } from '@ps-fe-authentication/api/queries';

import InterestCategoryList from './interest-category-list';

import type { ICategory } from '@ps-fe-authentication/components/interests/steps/categories/constants';

interface IInterestCategoryProps {
  category: ICategory;
}

const InterestCategory = ({ category }: IInterestCategoryProps) => {
  const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);
  const { t } = useTranslation();
  const { data: interests } = useInterestsQuery();

  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${PandoBreakpointsMd}`,
  });
  const filteredInterests = useMemo(
    () =>
      interests.filter((interest) => interest.categoryName === category.title),
    [category.title, interests],
  );

  const { icon: Icon } = category;

  return (
    <FlexContainer
      className={cs('interest-category', {
        'interest-category--full-screen-mode': fullScreenMode,
      })}
      direction="col"
      justify="flexStart"
      gap="1.5rem"
      align="flexStart"
    >
      <FlexContainer justify="flexStart" gap="0.5rem" align="center">
        {fullScreenMode && (
          <IconButton
            ariaLabel={t('interests.steps.interests.chevronLeft.ariaLabel')}
            palette="action"
            shape="square"
            size="lg"
            usage="text"
            onClick={() => setFullScreenMode(false)}
          >
            <ChevronLeftIcon color={PandoInkyBlueNeutralText300Dark} />
          </IconButton>
        )}
        <Icon role="presentation" />
        <h2 className="interest-category__title">{category.title}</h2>
        {!fullScreenMode && (
          <MediaQuery maxWidth={PandoBreakpointsMd}>
            <IconButton
              ariaLabel={t('interests.steps.interests.chevronRight.ariaLabel')}
              palette="action"
              shape="square"
              size="lg"
              usage="text"
              onClick={() => setFullScreenMode(true)}
            >
              <ChevronRightIcon color={PandoInkyBlueNeutralText300Dark} />
            </IconButton>
          </MediaQuery>
        )}
      </FlexContainer>
      {(!isTabletOrMobile || fullScreenMode) && (
        <InterestCategoryList interests={filteredInterests} />
      )}
    </FlexContainer>
  );
};

export default InterestCategory;
