import type { Country } from '@ps-fe-authentication/types';

export const COUNTRIES: Country[] = [
  {
    id: 1,
    name: 'Afghanistan',
    value: 'AF',
  },
  {
    id: 2,
    name: 'Albania',
    value: 'AL',
  },
  {
    id: 3,
    name: 'Algeria',
    value: 'DZ',
  },
  {
    id: 4,
    name: 'Andorra',
    value: 'AD',
  },
  {
    id: 5,
    name: 'Angola',
    value: 'AO',
  },
  {
    id: 6,
    name: 'Argentina',
    value: 'AR',
  },
  {
    id: 7,
    name: 'Armenia',
    value: 'AM',
  },
  {
    id: 8,
    name: 'Australia',
    value: 'AU',
  },
  {
    id: 9,
    name: 'Austria',
    value: 'AT',
  },
  {
    id: 10,
    name: 'Azerbaijan',
    value: 'AZ',
  },
  {
    id: 11,
    name: 'Bahamas',
    value: 'BS',
  },
  {
    id: 12,
    name: 'Bahrain',
    value: 'BH',
  },
  {
    id: 13,
    name: 'Bangladesh',
    value: 'BD',
  },
  {
    id: 14,
    name: 'Barbados',
    value: 'BB',
  },
  {
    id: 15,
    name: 'Belarus',
    value: 'BY',
  },
  {
    id: 16,
    name: 'Belgium',
    value: 'BE',
  },
  {
    id: 17,
    name: 'Belize',
    value: 'BZ',
  },
  {
    id: 18,
    name: 'Benin',
    value: 'BJ',
  },
  {
    id: 19,
    name: 'Bhutan',
    value: 'BT',
  },
  {
    id: 20,
    name: 'Bolivia',
    value: 'BO',
  },
  {
    id: 21,
    name: 'Bosnia and Herzegovina',
    value: 'BA',
  },
  {
    id: 22,
    name: 'Botswana',
    value: 'BW',
  },
  {
    id: 23,
    name: 'Brazil',
    value: 'BR',
  },
  {
    id: 24,
    name: 'Brunei',
    value: 'BN',
  },
  {
    id: 25,
    name: 'Bulgaria',
    value: 'BG',
  },
  {
    id: 26,
    name: 'Burkina Faso',
    value: 'BF',
  },
  {
    id: 27,
    name: 'Burundi',
    value: 'BI',
  },
  {
    id: 28,
    name: 'Cambodia',
    value: 'KH',
  },
  {
    id: 29,
    name: 'Cameroon',
    value: 'CM',
  },
  {
    id: 30,
    name: 'Canada',
    value: 'CA',
  },
  {
    id: 31,
    name: 'Cape Verde',
    value: 'CV',
  },
  {
    id: 32,
    name: 'Central African Republic',
    value: 'CF',
  },
  {
    id: 33,
    name: 'Chad',
    value: 'TD',
  },
  {
    id: 34,
    name: 'Chile',
    value: 'CL',
  },
  {
    id: 35,
    name: 'China',
    value: 'CN',
  },
  {
    id: 36,
    name: 'Colombia',
    value: 'CO',
  },
  {
    id: 37,
    name: 'Comoros',
    value: 'KM',
  },
  {
    id: 38,
    name: 'Congo (Congo-Brazzaville)',
    value: 'CG',
  },
  {
    id: 39,
    name: 'Costa Rica',
    value: 'CR',
  },
  {
    id: 40,
    name: 'Croatia',
    value: 'HR',
  },
  {
    id: 41,
    name: 'Cuba',
    value: 'CU',
  },
  {
    id: 42,
    name: 'Cyprus',
    value: 'CY',
  },
  {
    id: 43,
    name: 'Czech Republic',
    value: 'CZ',
  },
  {
    id: 44,
    name: 'Democratic Republic of the Congo',
    value: 'CD',
  },
  {
    id: 45,
    name: 'Denmark',
    value: 'DK',
  },
  {
    id: 46,
    name: 'Djibouti',
    value: 'DJ',
  },
  {
    id: 47,
    name: 'Dominica',
    value: 'DM',
  },
  {
    id: 48,
    name: 'Dominican Republic',
    value: 'DO',
  },
  {
    id: 49,
    name: 'Ecuador',
    value: 'EC',
  },
  {
    id: 50,
    name: 'Egypt',
    value: 'EG',
  },
  {
    id: 51,
    name: 'El Salvador',
    value: 'SV',
  },
  {
    id: 52,
    name: 'Equatorial Guinea',
    value: 'GQ',
  },
  {
    id: 53,
    name: 'Eritrea',
    value: 'ER',
  },
  {
    id: 54,
    name: 'Estonia',
    value: 'EE',
  },
  {
    id: 55,
    name: 'Eswatini',
    value: 'SZ',
  },
  {
    id: 56,
    name: 'Ethiopia',
    value: 'ET',
  },
  {
    id: 57,
    name: 'Fiji',
    value: 'FJ',
  },
  {
    id: 58,
    name: 'Finland',
    value: 'FI',
  },
  {
    id: 59,
    name: 'France',
    value: 'FR',
  },
  {
    id: 60,
    name: 'Gabon',
    value: 'GA',
  },
  {
    id: 61,
    name: 'Gambia',
    value: 'GM',
  },
  {
    id: 62,
    name: 'Georgia',
    value: 'GE',
  },
  {
    id: 63,
    name: 'Germany',
    value: 'DE',
  },
  {
    id: 64,
    name: 'Ghana',
    value: 'GH',
  },
  {
    id: 65,
    name: 'Greece',
    value: 'GR',
  },
  {
    id: 66,
    name: 'Grenada',
    value: 'GD',
  },
  {
    id: 67,
    name: 'Guatemala',
    value: 'GT',
  },
  {
    id: 68,
    name: 'Guinea',
    value: 'GN',
  },
  {
    id: 69,
    name: 'Guinea-Bissau',
    value: 'GW',
  },
  {
    id: 70,
    name: 'Guyana',
    value: 'GY',
  },
  {
    id: 71,
    name: 'Haiti',
    value: 'HT',
  },
  {
    id: 72,
    name: 'Honduras',
    value: 'HN',
  },
  {
    id: 73,
    name: 'Hungary',
    value: 'HU',
  },
  {
    id: 74,
    name: 'Iceland',
    value: 'IS',
  },
  {
    id: 75,
    name: 'India',
    value: 'IN',
  },
  {
    id: 76,
    name: 'Indonesia',
    value: 'ID',
  },
  {
    id: 77,
    name: 'Iran',
    value: 'IR',
  },
  {
    id: 78,
    name: 'Iraq',
    value: 'IQ',
  },
  {
    id: 79,
    name: 'Ireland',
    value: 'IE',
  },
  {
    id: 80,
    name: 'Israel',
    value: 'IL',
  },
  {
    id: 81,
    name: 'Italy',
    value: 'IT',
  },
  {
    id: 82,
    name: 'Jamaica',
    value: 'JM',
  },
  {
    id: 83,
    name: 'Japan',
    value: 'JP',
  },
  {
    id: 84,
    name: 'Jordan',
    value: 'JO',
  },
  {
    id: 85,
    name: 'Kazakhstan',
    value: 'KZ',
  },
  {
    id: 86,
    name: 'Kenya',
    value: 'KE',
  },
  {
    id: 87,
    name: 'Kiribati',
    value: 'KI',
  },
  {
    id: 88,
    name: 'Kuwait',
    value: 'KW',
  },
  {
    id: 89,
    name: 'Kyrgyzstan',
    value: 'KG',
  },
  {
    id: 90,
    name: 'Laos',
    value: 'LA',
  },
  {
    id: 91,
    name: 'Latvia',
    value: 'LV',
  },
  {
    id: 92,
    name: 'Lebanon',
    value: 'LB',
  },
  {
    id: 93,
    name: 'Lesotho',
    value: 'LS',
  },
  {
    id: 94,
    name: 'Liberia',
    value: 'LR',
  },
  {
    id: 95,
    name: 'Libya',
    value: 'LY',
  },
  {
    id: 96,
    name: 'Liechtenstein',
    value: 'LI',
  },
  {
    id: 97,
    name: 'Lithuania',
    value: 'LT',
  },
  {
    id: 98,
    name: 'Luxembourg',
    value: 'LU',
  },
  {
    id: 99,
    name: 'Madagascar',
    value: 'MG',
  },
  {
    id: 100,
    name: 'Malawi',
    value: 'MW',
  },
  {
    id: 101,
    name: 'Malaysia',
    value: 'MY',
  },
  {
    id: 102,
    name: 'Maldives',
    value: 'MV',
  },
  {
    id: 103,
    name: 'Mali',
    value: 'ML',
  },
  {
    id: 104,
    name: 'Malta',
    value: 'MT',
  },
  {
    id: 105,
    name: 'Marshall Islands',
    value: 'MH',
  },
  {
    id: 116,
    name: 'Mauritania',
    value: 'MR',
  },
  {
    id: 117,
    name: 'Mauritius',
    value: 'MU',
  },
  {
    id: 118,
    name: 'Mexico',
    value: 'MX',
  },
  {
    id: 119,
    name: 'Micronesia',
    value: 'FM',
  },
  {
    id: 120,
    name: 'Moldova',
    value: 'MD',
  },
  {
    id: 121,
    name: 'Monaco',
    value: 'MC',
  },
  {
    id: 122,
    name: 'Mongolia',
    value: 'MN',
  },
  {
    id: 123,
    name: 'Montenegro',
    value: 'ME',
  },
  {
    id: 124,
    name: 'Morocco',
    value: 'MA',
  },
  {
    id: 125,
    name: 'Mozambique',
    value: 'MZ',
  },
  {
    id: 126,
    name: 'Myanmar (Burma)',
    value: 'MM',
  },
  {
    id: 127,
    name: 'Namibia',
    value: 'NA',
  },
  {
    id: 128,
    name: 'Nauru',
    value: 'NR',
  },
  {
    id: 129,
    name: 'Nepal',
    value: 'NP',
  },
  {
    id: 130,
    name: 'Netherlands',
    value: 'NL',
  },
  {
    id: 131,
    name: 'New Zealand',
    value: 'NZ',
  },
  {
    id: 132,
    name: 'Nicaragua',
    value: 'NI',
  },
  {
    id: 133,
    name: 'Niger',
    value: 'NE',
  },
  {
    id: 134,
    name: 'Nigeria',
    value: 'NG',
  },
  {
    id: 135,
    name: 'North Korea',
    value: 'KP',
  },
  {
    id: 136,
    name: 'North Macedonia',
    value: 'MK',
  },
  {
    id: 137,
    name: 'Norway',
    value: 'NO',
  },
  {
    id: 138,
    name: 'Oman',
    value: 'OM',
  },
  {
    id: 139,
    name: 'Pakistan',
    value: 'PK',
  },
  {
    id: 140,
    name: 'Palau',
    value: 'PW',
  },
  {
    id: 141,
    name: 'Panama',
    value: 'PA',
  },
  {
    id: 142,
    name: 'Papua New Guinea',
    value: 'PG',
  },
  {
    id: 143,
    name: 'Paraguay',
    value: 'PY',
  },
  {
    id: 144,
    name: 'Peru',
    value: 'PE',
  },
  {
    id: 145,
    name: 'Philippines',
    value: 'PH',
  },
  {
    id: 146,
    name: 'Poland',
    value: 'PL',
  },
  {
    id: 147,
    name: 'Portugal',
    value: 'PT',
  },
  {
    id: 148,
    name: 'Qatar',
    value: 'QA',
  },
  {
    id: 149,
    name: 'Romania',
    value: 'RO',
  },
  {
    id: 150,
    name: 'Russia',
    value: 'RU',
  },
  {
    id: 151,
    name: 'Rwanda',
    value: 'RW',
  },
  {
    id: 152,
    name: 'Saint Kitts and Nevis',
    value: 'KN',
  },
  {
    id: 153,
    name: 'Saint Lucia',
    value: 'LC',
  },
  {
    id: 154,
    name: 'Saint Vincent and the Grenadines',
    value: 'VC',
  },
  {
    id: 155,
    name: 'Samoa',
    value: 'WS',
  },
  {
    id: 156,
    name: 'San Marino',
    value: 'SM',
  },
  {
    id: 157,
    name: 'Sao Tome and Principe',
    value: 'ST',
  },
  {
    id: 158,
    name: 'Saudi Arabia',
    value: 'SA',
  },
  {
    id: 159,
    name: 'Senegal',
    value: 'SN',
  },
  {
    id: 160,
    name: 'Serbia',
    value: 'RS',
  },
  {
    id: 161,
    name: 'Seychelles',
    value: 'SC',
  },
  {
    id: 162,
    name: 'Sierra Leone',
    value: 'SL',
  },
  {
    id: 163,
    name: 'Singapore',
    value: 'SG',
  },
  {
    id: 164,
    name: 'Slovakia',
    value: 'SK',
  },
  {
    id: 165,
    name: 'Slovenia',
    value: 'SI',
  },
  {
    id: 166,
    name: 'Solomon Islands',
    value: 'SB',
  },
  {
    id: 167,
    name: 'Somalia',
    value: 'SO',
  },
  {
    id: 168,
    name: 'South Africa',
    value: 'ZA',
  },
  {
    id: 169,
    name: 'South Korea',
    value: 'KR',
  },
  {
    id: 170,
    name: 'South Sudan',
    value: 'SS',
  },
  {
    id: 171,
    name: 'Spain',
    value: 'ES',
  },
  {
    id: 172,
    name: 'Sri Lanka',
    value: 'LK',
  },
  {
    id: 173,
    name: 'Sudan',
    value: 'SD',
  },
  {
    id: 174,
    name: 'Suriname',
    value: 'SR',
  },
  {
    id: 175,
    name: 'Sweden',
    value: 'SE',
  },
  {
    id: 176,
    name: 'Switzerland',
    value: 'CH',
  },
  {
    id: 177,
    name: 'Syria',
    value: 'SY',
  },
  {
    id: 178,
    name: 'Taiwan',
    value: 'TW',
  },
  {
    id: 179,
    name: 'Tajikistan',
    value: 'TJ',
  },
  {
    id: 180,
    name: 'Tanzania',
    value: 'TZ',
  },
  {
    id: 181,
    name: 'Thailand',
    value: 'TH',
  },
  {
    id: 182,
    name: 'Timor-Leste',
    value: 'TL',
  },
  {
    id: 183,
    name: 'Togo',
    value: 'TG',
  },
  {
    id: 184,
    name: 'Tonga',
    value: 'TO',
  },
  {
    id: 185,
    name: 'Trinidad and Tobago',
    value: 'TT',
  },
  {
    id: 186,
    name: 'Tunisia',
    value: 'TN',
  },
  {
    id: 187,
    name: 'Turkey',
    value: 'TR',
  },
  {
    id: 188,
    name: 'Turkmenistan',
    value: 'TM',
  },
  {
    id: 189,
    name: 'Tuvalu',
    value: 'TV',
  },
  {
    id: 190,
    name: 'Uganda',
    value: 'UG',
  },
  {
    id: 191,
    name: 'Ukraine',
    value: 'UA',
  },
  {
    id: 192,
    name: 'United Arab Emirates',
    value: 'AE',
  },
  {
    id: 193,
    name: 'United Kingdom',
    value: 'GB',
  },
  {
    id: 194,
    name: 'United States of America',
    value: 'US',
  },
  {
    id: 195,
    name: 'Uruguay',
    value: 'UY',
  },
  {
    id: 196,
    name: 'Uzbekistan',
    value: 'UZ',
  },
  {
    id: 197,
    name: 'Vanuatu',
    value: 'VU',
  },
  {
    id: 198,
    name: 'Vatican City',
    value: 'VA',
  },
  {
    id: 199,
    name: 'Venezuela',
    value: 'VE',
  },
  {
    id: 200,
    name: 'Vietnam',
    value: 'VN',
  },
  {
    id: 201,
    name: 'Yemen',
    value: 'YE',
  },
  {
    id: 202,
    name: 'Zambia',
    value: 'ZM',
  },
  {
    id: 203,
    name: 'Zimbabwe',
    value: 'ZW',
  },
];
