// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.proficiency__card {
  max-width: 32rem;
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 1.5rem;
  color: var(--pando-colors-action-text-initial, #e1ddf7);
  border-radius: 0.25rem;
  border: 1px solid var(--pando-inky-blue-neutral-surface-200-dark, #242145);
  background: var(--pando-inky-blue-neutral-surface-200-dark, #242145);
}
@media (min-width: 48rem) {
  .proficiency__card {
    gap: 1rem !important;
  }
}
.proficiency__label {
  flex: 1 1 50%;
  font-family: var(--font-pando-body, "PS TT Commons"), sans-serif;
  font-size: var(--font-size-md, 1rem);
  font-weight: 700;
  line-height: 150%;
}
.proficiency__select {
  flex: 0 0 100%;
}
@media (min-width: 48rem) {
  .proficiency__select {
    flex: 0 1 12.5rem;
  }
}
.proficiency__select .pando-select__wrapper {
  margin-top: 0;
}
.proficiency__select select {
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/interests/steps/proficiency/proficiency.scss"],"names":[],"mappings":"AAEE;EACE,gBAAA;EACA,WAAA;EACA,oCAAA;EAEA,uDAAA;EACA,sBAAA;EACA,0EAAA;EACA,oEAAA;AAFJ;AAII;EAVF;IAWI,oBAAA;EADJ;AACF;AAIE;EACE,aAAA;EAEA,gEAAA;EACA,oCAAA;EACA,gBAAA;EACA,iBAAA;AAHJ;AAME;EACE,cAAA;AAJJ;AAMI;EAHF;IAII,iBAAA;EAHJ;AACF;AAKI;EACE,aAAA;AAHN;AAMI;EACE,kCAAA;EACA,YAAA;AAJN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.proficiency {\n  &__card {\n    max-width: 32rem;\n    width: 100%;\n    padding: 0.75rem 1rem 0.75rem 1.5rem;\n\n    color: var(--pando-colors-action-text-initial, #e1ddf7);\n    border-radius: 0.25rem;\n    border: 1px solid var(--pando-inky-blue-neutral-surface-200-dark, #242145);\n    background:var(--pando-inky-blue-neutral-surface-200-dark, #242145);\n\n    @media (min-width: $pando-breakpoints-md) {\n      gap: 1rem !important;\n    }\n  }\n\n  &__label {\n    flex: 1 1 50%;\n\n    font-family: var(--font-pando-body, \"PS TT Commons\"), sans-serif;\n    font-size: var(--font-size-md, 1rem);\n    font-weight: 700;\n    line-height: 150%;\n  }\n\n  &__select {\n    flex: 0 0 100%;\n\n    @media (min-width: $pando-breakpoints-md) {\n      flex: 0 1 12.5rem;\n    }\n\n    .pando-select__wrapper {\n      margin-top: 0;\n    }\n\n    select {\n      padding: 0.5rem 0.5rem 0.5rem 1rem;\n      height: auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
