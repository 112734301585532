// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invitation-expired__main {
  background-color: var(--pando-colors-neutral-surface-100);
  flex-direction: column;
  align-items: center;
  padding: var(--pando-padding-lg, 1.5rem);
  width: var(--pando-sizes-sm);
  border-radius: var(--pando-radii-lg);
  margin: var(--pando-spacing-32) 0;
}
.invitation-expired__header {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--pando-spacing-5);
}
.invitation-expired__header__title {
  font-size: var(--pando-font-sizes-2xl);
  font-weight: 600;
}
.invitation-expired__body {
  flex-direction: column;
  margin: var(--pando-spacing-5) 0;
  align-items: center;
}
.invitation-expired__footer {
  width: 100%;
}
.invitation-expired__footer button {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/create-account/invitation-expired/invitation-expired.scss"],"names":[],"mappings":"AAEE;EACE,yDAAA;EACA,sBAAA;EACA,mBAAA;EACA,wCAAA;EACA,4BAAA;EACA,oCAAA;EACA,iCAAA;AADJ;AAIE;EACE,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,qCAAA;AAFJ;AAII;EACE,sCAAA;EACA,gBAAA;AAFN;AAME;EACE,sBAAA;EACA,gCAAA;EACA,mBAAA;AAJJ;AAOE;EACE,WAAA;AALJ;AAOI;EACE,WAAA;AALN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.invitation-expired {\n  &__main {\n    background-color: var(--pando-colors-neutral-surface-100);\n    flex-direction: column;\n    align-items: center;\n    padding: var(--pando-padding-lg, 1.5rem);\n    width: var(--pando-sizes-sm);\n    border-radius: var(--pando-radii-lg);\n    margin: var(--pando-spacing-32) 0;\n  }\n\n  &__header {\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: var(--pando-spacing-5);\n\n    &__title {\n      font-size: var(--pando-font-sizes-2xl);\n      font-weight: 600;\n    }\n  }\n\n  &__body {\n    flex-direction: column;\n    margin: var(--pando-spacing-5) 0;\n    align-items: center;\n  }\n\n  &__footer {\n    width: 100%;\n\n    button {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
