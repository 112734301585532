import httpClient from '@ps-fe-authentication/api/http-client';

import type { IInterest } from '@ps-fe-authentication/types';

const API_VERSION = '/v1';
const INTERESTS_PATH = '/profile/interests';

export const fetchInterests = async (): Promise<IInterest[]> => {
  const response = await httpClient.get<IInterest[]>(
    API_VERSION + INTERESTS_PATH,
  );

  return response.data;
};
