import Cookies from 'js-cookie';

import type {
  AuthorizationParams,
  TenantDetails,
} from '@ps-fe-authentication/types';

export const getAuthConfig = () => {
  const tenantDetails = JSON.parse(Cookies.get('tenantDetails') || '{}');

  if (
    !tenantDetails?.AUTH0_CLIENT_ID ||
    !tenantDetails?.AUTH0_DOMAIN ||
    !tenantDetails?.AUTH0_AUDIENCE
  ) {
    return null;
  }

  return tenantDetails;
};

export const getRedirectUrl = (baseUrl: string, callbackPath: string) => {
  const redirectToUrl = localStorage.getItem('redirectToUrl')?.trim();
  const baseRedirectUrl = `${baseUrl}${callbackPath}`;

  return redirectToUrl && redirectToUrl !== '/'
    ? `${baseRedirectUrl}?redirect_to_url=${encodeURIComponent(redirectToUrl.replace(/^\/+/, ''))}`
    : baseRedirectUrl;
};

export const buildAuthUrl = (
  config: TenantDetails,
  redirectUri: string,
  additionalParams?: AuthorizationParams,
) => {
  const { AUTH0_AUDIENCE, AUTH0_DOMAIN, AUTH0_CLIENT_ID } = config;

  const url = new URL(`https://${AUTH0_DOMAIN}/authorize`);

  const defaultQueryParams: [string, string][] = [
    ['response_type', 'code'],
    ['client_id', AUTH0_CLIENT_ID],
    ['scope', 'openid profile email offline_access'],
    ['audience', AUTH0_AUDIENCE],
    ['response_mode', 'query'],
    ['redirect_uri', redirectUri],
  ];

  defaultQueryParams.forEach(([key, value]) =>
    url.searchParams.set(key, value),
  );

  if (additionalParams) {
    Object.entries(additionalParams)
      .filter(([_, value]) => value !== undefined && value !== null)
      .forEach(([key, value]) => {
        url.searchParams.set(key, value.toString());
      });
  }

  return url;
};
