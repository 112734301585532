import { FlexContainer } from '@pluralsight/react-ng';
import './content-header.scss';

interface IContentHeaderProps {
  title: string;
  subtitle?: string;
}

const ContentHeader = ({ title, subtitle }: IContentHeaderProps) => {
  return (
    <FlexContainer
      className="content-header"
      gap="var(--pando-spacing-3)"
      direction="col"
      align="center"
    >
      <h1 className="content-header__title">{title}</h1>
      <p className="content-header__subtitle">{subtitle}</p>
    </FlexContainer>
  );
};

export default ContentHeader;
