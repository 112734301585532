export const initialFormState = {
  username: { invalid: false, required: true },
};

export const initialFromFields = {
  username: '',
};

export enum KeyboardEventLoginPage {
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
}

export enum LoginOptions {
  Individual = 'individual',
  Business = 'business',
}
