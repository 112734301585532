import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getCurrentUser } from '@ps-fe-authentication/api/services/user-service';

import type { AxiosError } from 'axios';
import type { CurrentUserData } from '@ps-fe-authentication/types/login';

interface ReturnAuthAppProps {
  isCurrentUserLoading: boolean;
  currentUser: CurrentUserData | null;
  fetchCurrentUser: () => Promise<void>;
}

const useAuthApp = (): ReturnAuthAppProps => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isCurrentUserLoading, setIsCurrentUserLoading] =
    useState<boolean>(true);
  const [currentUser, setCurrentUser] = useState<CurrentUserData | null>(null);

  const fetchCurrentUser = async () => {
    try {
      const data = await getCurrentUser();

      setCurrentUser(data);
    } catch (err) {
      if ((err as AxiosError).response?.status === 401) {
        // TODO (vladyslav-mashkin): We need a proper solution to not redirect to login when accessing public pages (NGP-4446)
        const queryParams = new URLSearchParams(location.search);

        const isInviteError =
          location.pathname === '/login' &&
          queryParams.get('invite') &&
          queryParams.get('error');

        if (!isInviteError) {
          navigate('/login');
        }
      } else {
        console.error('Error fetching current user:', err);
      }

      setCurrentUser(null);
      sessionStorage.removeItem('userLoggedIn');
    } finally {
      setIsCurrentUserLoading(false);
    }
  };

  return {
    isCurrentUserLoading,
    currentUser,
    fetchCurrentUser,
  };
};

export default useAuthApp;
