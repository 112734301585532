import {
  Button,
  FlexContainer,
  FlexItem,
  TextLink,
} from '@pluralsight/react-ng';
import React from 'react';

import ErrorIcon from '@ps-fe-authentication/assets/error.svg';
import './invitation-expired.scss';
import { useTranslations } from '@ps-fe-authentication/contexts/TranslationsContext';

const InvitationExpired: React.FC = () => {
  const { t } = useTranslations();

  return (
    <FlexContainer className="invitation-expired__main">
      <FlexContainer className="invitation-expired__header">
        <FlexItem>
          <img
            src={ErrorIcon}
            alt=""
            role="presentation"
            className="invitation-expired_header__logo"
          />
        </FlexItem>
        <FlexItem>
          <h1 className="invitation-expired__header__title">
            {t('invitationExpired.title')}
          </h1>
        </FlexItem>
      </FlexContainer>
      <FlexItem className="invitation-expired__body">
        <p className="invitation-expired__text">
          {t('invitationExpired.description')}{' '}
          <TextLink
            href="https://help.pluralsight.com/hc/en-us/requests/new"
            aria-label={t('invitationExpired.ariaLabel.contactSupport')}
          >
            {t('invitationExpired.contact')}
          </TextLink>
        </p>
      </FlexItem>
      <FlexItem className="invitation-expired__footer">
        <Button
          onClick={() => (window.location.href = '/')}
          type="button"
          palette="action"
        >
          {t('invitationExpired.return')}
        </Button>
      </FlexItem>
    </FlexContainer>
  );
};

export default InvitationExpired;
