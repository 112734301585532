import { InterestsProvider } from '@ps-fe-authentication/contexts/InterestsContext';
import { useInterestsQuery } from '@ps-fe-authentication/api/queries';
import Interests from '@ps-fe-authentication/components/interests';

const InterestsPage = () => {
  useInterestsQuery();

  return (
    <InterestsProvider>
      <Interests />
    </InterestsProvider>
  );
};

export default InterestsPage;
