import { FlexContainer } from '@pluralsight/react-ng';

import ContentHeader from '@ps-fe-authentication/components/interests/content-header';
import { steps } from '@ps-fe-authentication/constants/interests';
import { useCurrentStep } from '@ps-fe-authentication/contexts/InterestsContext';
import { useTranslations } from '@ps-fe-authentication/contexts/TranslationsContext';

import Step from './step/step';

import type { FC, HTMLAttributes } from 'react';

import './stepper.scss';

const Stepper: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
  const { t } = useTranslations();

  const { currentStep } = useCurrentStep();

  const currentStepData = steps[currentStep - 1];

  if (!currentStepData) return null;

  const {
    title,
    subtitle,
    stepContentComponent: StepContentComponent,
  } = currentStepData;

  return (
    <FlexContainer
      className="stepper"
      gap="2rem"
      direction="col"
      align="center"
      {...props}
    >
      <FlexContainer
        gap="1.5rem"
        align="center"
        justify="center"
        className="stepper__steps"
      >
        {steps.map(({ label }, index) => (
          <Step
            key={index}
            label={t(label)}
            stepNumber={index + 1}
            isCurrent={index + 1 === currentStep}
            isCompleted={index + 1 < currentStep}
          />
        ))}
      </FlexContainer>
      <ContentHeader title={t(title)} subtitle={t(subtitle)} />
      <StepContentComponent />
    </FlexContainer>
  );
};

export default Stepper;
