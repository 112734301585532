import { useNavigate, useSearchParams } from 'react-router-dom';
import { useToast } from '@pluralsight/react-ng';
import { useEffect } from 'react';

import LoginForm from '@ps-fe-authentication/components/login/login-form';
import useAuthApp from '@ps-fe-authentication/hooks/useAuthApp';
import {
  CUSTOM_EVENTS,
  sendCustomEvent,
} from '@ps-fe-authentication/hooks/use-custom-event';
import { setupTenant } from '@ps-fe-authentication/utils/tenant-config';
import { ERRORS } from '@ps-fe-authentication/constants';
import { useTranslations } from '@ps-fe-authentication/contexts/TranslationsContext';
import { useAppConfig } from '@ps-fe-authentication/contexts/AppConfigContext';

const { EXPIRED_INVITATION, NOT_B2B_USER_REGEX } = ERRORS;

const LoginPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const appConfig = useAppConfig();

  const { show } = useToast();

  const { t } = useTranslations();

  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const isInvite = searchParams.get('invite');
  const error = searchParams.get('error');
  const errorDescription = searchParams.get('error_description');

  const { isCurrentUserLoading, currentUser, fetchCurrentUser } = useAuthApp();

  useEffect(() => {
    setupTenant(appConfig);
    void fetchCurrentUser();
  }, []);

  useEffect(() => {
    const storeAuthDataInSession = () => {
      if (error && errorDescription === EXPIRED_INVITATION) {
        navigate(`/create-account?error=invitation_expired`);

        return;
      }

      if (
        error &&
        errorDescription &&
        errorDescription.match(NOT_B2B_USER_REGEX)
      ) {
        show({
          text: t('loginErrors.wrongAccountType'),
          palette: 'danger',
          duration: 8000,
          onClose: (): void => {},
        });
      }

      if (currentUser) {
        const appMetadata = {
          country: currentUser.country,
          psId: currentUser.psUserId,
          terms_accepted: currentUser.isTermsAccepted,
          terms_accepted_created_at: currentUser.acceptedTermsOn,
        };

        const authSession = {
          isAuthenticated: true,
          user: {
            userId: currentUser.psUserId,
            username: `${currentUser.firstName} ${currentUser.lastName}`,
            email: currentUser.email,
            appMetadata,
          },
        };

        sessionStorage.setItem(
          'userLoggedIn',
          JSON.stringify(authSession.user),
        );

        sendCustomEvent(CUSTOM_EVENTS.USER_UPDATED, authSession.user);

        if (appMetadata.psId && !appMetadata.terms_accepted) {
          navigate(
            `/create-account?id=${appMetadata.psId}&email=${currentUser.email}`,
          );

          return;
        }

        const redirectToUrl = localStorage.getItem('redirectToUrl');

        window.location.href =
          redirectToUrl && redirectToUrl !== '/' ? redirectToUrl : '/';
      } else if (code && state && isInvite) {
        navigate('/login');
      }
    };

    void storeAuthDataInSession();
  }, [currentUser]);

  if (code && state) {
    return null;
  }

  if (isCurrentUserLoading || currentUser) {
    return null;
  }

  return <LoginForm />;
};

export default LoginPage;
