import { DangerDiamondFilledIcon } from '@pluralsight/icons';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseIconButton,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogHeading,
  FlexContainer,
} from '@pluralsight/react-ng';
import React, { forwardRef } from 'react';
import './custom-dialog.scss';

type HeaderType = { [key: string]: string };

type ContentType = { [key: string]: React.JSX.Element };

interface DialogProps {
  id: string;
  step: string;
  stepsNumber: number;
  dialogHeader: HeaderType;
  dialogContent: ContentType;
  isWarning?: boolean;
  multipleSteps?: boolean;
  actionButtons: (step: string) => React.JSX.Element;
  closeModal: () => void;
}

const CustomDialogComponent = forwardRef<HTMLDialogElement | null, DialogProps>(
  (
    {
      id,
      step,
      stepsNumber,
      dialogHeader,
      dialogContent,
      isWarning,
      multipleSteps = true,
      actionButtons,
      closeModal,
    },
    ref,
  ) => {
    return (
      <AlertDialog
        ref={ref}
        headingId="dialog-header"
        bodyId="dialog-body"
        className="custom-dialog-authentication"
      >
        <AlertDialogCloseIconButton onClick={closeModal} />
        <FlexContainer
          className="custom-dialog-authentication__status-section"
          align="center"
          direction="row"
          justify="spaceBetween"
        >
          {multipleSteps ? (
            <p className="custom-dialog-authentication__status-section-step">{`${step}/${stepsNumber}`}</p>
          ) : (
            <></>
          )}
        </FlexContainer>
        <AlertDialogHeader>
          <AlertDialogHeading>
            <FlexContainer direction="row" gap={10}>
              {isWarning && (
                <div className="custom-dialog-authentication__warning">
                  <DangerDiamondFilledIcon />
                </div>
              )}
              {dialogHeader[step]}
            </FlexContainer>
          </AlertDialogHeading>
        </AlertDialogHeader>
        <AlertDialogBody id={id}>{dialogContent[step]}</AlertDialogBody>
        <AlertDialogFooter>{actionButtons(step)}</AlertDialogFooter>
      </AlertDialog>
    );
  },
);

export default CustomDialogComponent;
