import { useCallback } from 'react';

const useAccessibleKeyDown = (onClick?: () => void) =>
  useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      if (onClick && (e.key === 'Enter' || e.key === ' ')) {
        onClick();
        e.preventDefault();
      }
    },
    [onClick],
  );

export default useAccessibleKeyDown;
