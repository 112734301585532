import Cookies from 'js-cookie';

import type { AppConfig } from '@ps-fe-authentication/contexts/AppConfigContext';

export const setupTenant = (config: AppConfig) => {
  let provisionedRegion = Cookies.get('provisionedRegion') as 'EU' | 'US';
  let accountType = Cookies.get('accountType') as 'B2C' | 'B2B';

  if (!provisionedRegion) {
    provisionedRegion = config.COMMON.PROVISIONED_REGION as 'EU' | 'US';
    accountType = 'B2C';
    Cookies.set('accountType', accountType, { expires: 1 });
    Cookies.set('provisionedRegion', provisionedRegion, { expires: 1 });

    const selectedTenant =
      config.COMMON.TENANTS[accountType][provisionedRegion];

    if (!selectedTenant) {
      console.error('No tenant configuration found for:', accountType);

      return;
    }

    Cookies.set('tenantDetails', JSON.stringify(selectedTenant), {
      expires: 1,
    });

    console.log('Provisioned region set to default:', provisionedRegion);
  }
};
