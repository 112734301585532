// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.base-container {
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: var(--pando-spacing-4, 1rem);
}
.base-container__header {
  padding: 20px 0;
  margin-bottom: var(--pando-spacing-6);
  border-bottom: 1px solid var(--pando-colors-neutral-border-100);
}
.base-container__header__logo {
  height: 32px;
}
.base-container__body {
  flex: 1 0 auto;
}
.base-container__footer {
  height: 56px;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--pando-spacing-6);
}
.base-container__footer__copyright, .base-container__footer__links {
  display: flex;
  flex-direction: row;
  color: var(--pando-colors-neutral-text-100);
}
.base-container__footer__copyright img,
.base-container__footer__copyright a, .base-container__footer__links img,
.base-container__footer__links a {
  margin-right: 15px;
}
.base-container__footer__copyright a, .base-container__footer__links a {
  text-decoration: underline;
}
@media (max-width: 640px) {
  .base-container__footer {
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .base-container__footer a {
    margin-top: var(--pando-spacing-4);
  }
  .base-container__footer__copyright__text {
    display: flex;
    flex-direction: column;
  }
}

.language-selector {
  display: flex;
  flex-direction: row;
  border: 1px solid #5145a6;
  border-radius: 5px;
  padding: 2px 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/container/container.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,8BAAA;EACA,qCAAA;AAAF;AAEE;EACE,eAAA;EACA,qCAAA;EACA,+DAAA;AAAJ;AAEI;EACE,YAAA;AAAN;AAIE;EACE,cAAA;AAFJ;AAKE;EACE,YAAA;EACA,mBAAA;EACA,8BAAA;EACA,iCAAA;AAHJ;AAKI;EAEE,aAAA;EACA,mBAAA;EACA,2CAAA;AAJN;AAMM;;;EAEE,kBAAA;AAHR;AAMM;EACE,0BAAA;AAJR;AAQI;EAtBF;IAuBI,sBAAA;IACA,YAAA;IACA,2BAAA;IACA,uBAAA;EALJ;EAMI;IACE,kCAAA;EAJN;EAMI;IACE,aAAA;IACA,sBAAA;EAJN;AACF;;AASA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,kBAAA;EACA,iBAAA;AANF","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.base-container {\n  width: 100%;\n  height: 100vh;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  padding: var(--pando-spacing-4, 1rem);\n\n  &__header {\n    padding: 20px 0;\n    margin-bottom: var(--pando-spacing-6);\n    border-bottom: 1px solid var(--pando-colors-neutral-border-100);\n\n    &__logo {\n      height: 32px;\n    }\n  }\n\n  &__body {\n    flex: 1 0 auto;\n  }\n\n  &__footer {\n    height: 56px;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0 var(--pando-spacing-6);\n\n    &__copyright,\n    &__links {\n      display: flex;\n      flex-direction: row;\n      color: var(--pando-colors-neutral-text-100);\n\n      img,\n      a {\n        margin-right: 15px;\n      }\n\n      a {\n        text-decoration: underline;\n      }\n    }\n\n    @media (max-width: 640px) {\n      flex-direction: column;\n      height: auto;\n      justify-content: flex-start;\n      align-items: flex-start;\n      a {\n        margin-top: var(--pando-spacing-4);\n      }\n      &__copyright__text {\n        display: flex;\n        flex-direction: column;\n      }\n    }\n  }\n}\n\n.language-selector {\n  display: flex;\n  flex-direction: row;\n  border: 1px solid #5145a6;\n  border-radius: 5px;\n  padding: 2px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
