// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interests {
  max-width: 59rem;
}
.interests__categories-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(18.3333333333rem, 1fr));
  max-width: 59rem;
  width: 100%;
}
@media (max-width: 48rem) {
  .interests__categories-grid {
    grid-template-columns: 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/interests/steps/interests/interests.scss"],"names":[],"mappings":"AAGA;EACE,gBAHU;AACZ;AAIE;EACE,aAAA;EACA,SAAA;EACA,sEAAA;EACA,gBAAA;EACA,WAAA;AAFJ;AAII;EAPF;IAQI,0BAAA;EADJ;AACF","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n$max-width: 59rem;\n\n.interests {\n  max-width: $max-width;\n\n  &__categories-grid {\n    display: grid;\n    gap: 2rem;\n    grid-template-columns: repeat(auto-fit, minmax(calc((59rem - 4rem) / 3), 1fr));\n    max-width: 59rem;\n    width: 100%;\n\n    @media (max-width: $pando-breakpoints-md) {\n      grid-template-columns: 1fr;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
