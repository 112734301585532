// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form__form .pando-label {
  font-weight: 700;
}
.login-form__form .pando-label.invalid {
  font-weight: 500;
  color: var(--pando-colors-danger-text-200);
}
.login-form__form .pando-input__root {
  padding-inline-end: 0;
}
.login-form__form .pando-input__icon {
  color: var(--pando-colors-danger-text-100);
  right: 0.5em;
}`, "",{"version":3,"sources":["webpack://./src/components/login/login-form/login-form.scss"],"names":[],"mappings":"AAIM;EACE,gBAAA;AAHR;AAKQ;EACE,gBAAA;EACA,0CAAA;AAHV;AAQQ;EACE,qBAAA;AANV;AASQ;EACE,0CAAA;EACA,YAAA;AAPV","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.login-form {\n  &__form {\n    .pando {\n      &-label {\n        font-weight: 700;\n\n        &.invalid {\n          font-weight: 500;\n          color: var(--pando-colors-danger-text-200);\n        }\n      }\n\n      &-input {\n        &__root {\n          padding-inline-end: 0;\n        }\n\n        &__icon {\n          color: var(--pando-colors-danger-text-100);\n          right: 0.5em;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
