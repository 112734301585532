import { Button } from '@pluralsight/react-ng';
import './decline-invitation-dialog.scss';
import React, { forwardRef } from 'react';

import CustomDialogComponent from '@ps-fe-authentication/components/custom-dialog/custom-dialog';
import { useTranslations } from '@ps-fe-authentication/contexts/TranslationsContext';

interface IResendInviteDialogProps {
  planName?: string;
  closeModal: () => void;
  handleDecline: () => void;
  step: '0' | '1' | '2';
}

type HeaderType = { [key: string]: string };

type ContentType = { [key: string]: React.JSX.Element };

const DeclineInvitationDialogComponent = forwardRef<
  HTMLDialogElement | null,
  IResendInviteDialogProps
>(({ planName, closeModal, handleDecline, step }, ref) => {
  const { t } = useTranslations();

  const handleAction = () => {
    handleDecline();
    closeModal();
  };

  const dialogHeader: HeaderType = {
    1: `${t('declineInvitationDialog.title')}`,
  };

  const dialogContent: ContentType = {
    1: (
      <div className="decline-invitation-dialog__content">
        <div className="decline-invitation-dialog__content__description">
          {t('declineInvitationDialog.description', { planName })}
        </div>
        <div>{t('declineInvitationDialog.textWarning')}</div>
      </div>
    ),
  };

  const getActionButtons = (step: string): React.JSX.Element => {
    switch (step) {
      case '1':
        return (
          <>
            <Button
              size="lg"
              palette="action"
              usage="outline"
              onClick={closeModal}
            >
              {t('declineInvitationDialog.goBack')}
            </Button>
            <Button
              size="lg"
              palette="danger"
              usage="filled"
              onClick={handleAction}
            >
              {t('declineInvitationDialog.decline')}
            </Button>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <CustomDialogComponent
      id="decline-invitation"
      ref={ref}
      step={step}
      stepsNumber={1}
      dialogHeader={dialogHeader}
      dialogContent={dialogContent}
      actionButtons={getActionButtons}
      multipleSteps={false}
      isWarning={false}
      closeModal={closeModal}
    />
  );
});

export default DeclineInvitationDialogComponent;
