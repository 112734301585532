import { FlexContainer } from '@pluralsight/react-ng';
import { PandoBreakpointsSm } from '@pluralsight/design-tokens';
import cs from 'classnames';
import MediaQuery from 'react-responsive';

interface IStep {
  stepNumber: number;
  label: string;
  isCurrent: boolean;
  isCompleted: boolean;
}

const Step = ({ stepNumber, label, isCurrent, isCompleted }: IStep) => {
  return (
    <FlexContainer
      gap="var(--pando-spacing-4)"
      align="center"
      justify="center"
      aria-current={isCurrent ? 'step' : undefined}
      className="step-container"
    >
      <FlexContainer
        justify="center"
        align="center"
        className={cs('step-number', {
          current: isCurrent,
        })}
      >
        {isCompleted ? '✓' : stepNumber}
      </FlexContainer>
      <MediaQuery minWidth={PandoBreakpointsSm}>
        <h1
          className={cs('step-title', {
            current: isCurrent,
          })}
        >
          {label}
        </h1>
      </MediaQuery>
    </FlexContainer>
  );
};

export default Step;
