import { FlexContainer } from '@pluralsight/react-ng';
import './card.scss';
import cs from 'classnames';

import useAccessibleKeyDown from '@ps-fe-authentication/hooks/useAccessibleKeyDown';

import type { ElementType } from 'react';

interface ICategoriesCardProps {
  icon: ElementType;
  title: string;
  isSelected?: boolean;
  onClick?: () => void;
}
const Card = ({
  icon: Icon,
  title,
  isSelected = false,
  onClick,
}: ICategoriesCardProps) => {
  const handleKeyDown = useAccessibleKeyDown(onClick);

  return (
    <FlexContainer
      gap="1rem"
      align="center"
      justify="center"
      direction="col"
      className={cs('categories-card', {
        selected: isSelected,
      })}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      aria-pressed={isSelected}
    >
      <Icon aria-hidden="true" className="categories-card__icon" />
      <h3 className="categories-cards__title">{title}</h3>
    </FlexContainer>
  );
};

export default Card;
