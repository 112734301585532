import { FlexContainer } from '@pluralsight/react-ng';

import { useSelectedInterests } from '@ps-fe-authentication/contexts/InterestsContext';

import ProficiencyCard from './proficiencyCard';
import './proficiency.scss';

import type { FC } from 'react';

const Proficiency: FC = () => {
  const { selectedInterests, setInterestProficiency } = useSelectedInterests();

  return (
    <FlexContainer
      direction="col"
      gap="1rem"
      justify="flexStart"
      align="center"
    >
      {selectedInterests.map((interest) => (
        <ProficiencyCard
          key={interest.id}
          interestName={interest.name}
          proficiency={interest.proficiency}
          onProficiencyChange={(proficiency) =>
            setInterestProficiency({ id: interest.id, proficiency })
          }
        />
      ))}
    </FlexContainer>
  );
};

export default Proficiency;
