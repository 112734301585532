import {
  Button,
  Checkbox,
  FieldMessage,
  FlexContainer,
  FlexItem,
  FormControlProvider,
  Input,
  Label,
  Option,
  Select,
  Show,
  useToast,
} from '@pluralsight/react-ng';
import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import './create-account.scss';
import { useTranslations } from '@ps-fe-authentication/contexts/TranslationsContext';
import {
  useCountriesQuery,
  useUserByPsIdQuery,
} from '@ps-fe-authentication/api/queries';
import { useCreateAccountByIdMutation } from '@ps-fe-authentication/api/mutations';

import type { Account } from '@ps-fe-authentication/types';

const initialFormState = {
  firstName: { invalid: false, required: true },
  lastName: { invalid: false, required: true },
  country: { invalid: false, required: true },
  acceptTerms: { invalid: false, required: true },
};

const initialFormFields = {
  firstName: '',
  lastName: '',
  country: '0',
  acceptTerms: false,
};

const CreateAccount: React.FC = () => {
  const { show } = useToast();
  const { t } = useTranslations();
  const [searchParams] = useSearchParams();

  const psId = searchParams.get('id');
  const email = searchParams.get('email');
  const typeParam = searchParams.get('type');

  const type = typeParam ? typeParam.toUpperCase() : 'B2C';

  const { data: user, refetch: refetchUser } = useUserByPsIdQuery({ psId });
  const { data: countries } = useCountriesQuery();

  const updateUserLoggedIn = () => {
    const userLogged = JSON.parse(
      sessionStorage.getItem('userLoggedIn') || '{}',
    );

    const authSession = {
      isAuthenticated: true,
      user: {
        ...userLogged,
        username: `${formFields.firstName} ${formFields.lastName}`,
        appMetadata: {
          psId,
          country: formFields.country,
          terms_accepted: true,
        },
      },
    };

    // Emit user info to the event bus
    sessionStorage.setItem('userLoggedIn', JSON.stringify(authSession.user));
    (window as any).globalEventBus.publish('authChanged', authSession);

    window.location.href = '/';
  };

  const { mutate: createAccountById } = useCreateAccountByIdMutation({
    onError: (error) => {
      show({
        heading: t('createAccountForm.userCreatedHeaderUnSuccessfully'),
        text: `${t('createAccountForm.userCreatedTextUnSuccessfully')} ${error.message}`,
        palette: 'danger',
        duration: 8000,
        onClose: () => {},
      });
    },
    onSuccess: async () => {
      show({
        heading: t('createAccountForm.userCreatedHeaderSuccessfully'),
        text: t('createAccountForm.userCreatedTextSuccessfully'),
        palette: 'info',
        duration: 8000,
        onClose: (): void => {},
      });

      await refetchUser();
      updateUserLoggedIn();
    },
  });

  useEffect(() => {
    if (user) {
      setFormFields((prev) => ({
        ...prev,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
      }));
    }
  }, [user]);

  const [formState, setFormState] = useState(initialFormState);
  const [formFields, setFormFields] = useState<Account>(initialFormFields);

  const validateField = (name: string, value: string | boolean) => {
    switch (name) {
      case 'firstName':
      case 'lastName':
        return typeof value === 'string' && value.trim() === '';
      case 'country':
        return (
          typeof value === 'string' &&
          (value.trim() === '' || value.trim() === '0')
        );
      case 'acceptTerms':
        return typeof value === 'boolean' && !value;
      default:
        return false;
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    const fieldName = name as keyof typeof formState;

    setFormFields((prev) => ({ ...prev, [name]: fieldValue }));
    setFormState((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        invalid: validateField(fieldName, fieldValue),
      },
    }));
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    const fieldName = name as keyof typeof formState;

    setFormFields((prev) => ({ ...prev, [name]: value }));
    setFormState((prev) => ({
      ...prev,
      [fieldName]: { ...prev[fieldName], invalid: validateField(name, value) },
    }));
  };

  const isFormInvalid = () =>
    Object.values(formState).some((field) => field.invalid) ||
    formFields.country === '0' ||
    !formFields.acceptTerms;

  const handleCreateAccount = () => {
    if (psId && type) {
      const data = {
        firstName: formFields.firstName,
        lastName: formFields.lastName,
        country: formFields.country,
        isTermsAccepted: true,
      };

      if (user && user.id) {
        createAccountById({ psId, data });
      }
    }
  };

  return (
    <FlexContainer className="create-account__main">
      <FlexContainer className="create-account__header">
        <h1 className="create-account__header__title">
          {' '}
          {t('createAccountForm.createYourAccountTitle')}{' '}
        </h1>
      </FlexContainer>
      <FlexContainer className="create-account__body">
        <form
          className="create-account__form"
          onSubmit={(e) => e.preventDefault()}
        >
          <FlexContainer className="create-account__form__container">
            <FlexItem>
              <FormControlProvider {...formState.country}>
                <p className="create-account__form__container__email">
                  {email}
                </p>
              </FormControlProvider>
            </FlexItem>
            <FlexItem>
              <FormControlProvider {...formState.firstName}>
                <Label
                  htmlFor="firstName"
                  className={formState.firstName.invalid ? 'invalid' : ''}
                >
                  {t('createAccountForm.firstname')}
                </Label>
                <Input
                  id="firstName"
                  name="firstName"
                  value={formFields.firstName}
                  onChange={handleInputChange}
                />
                {formState.firstName.invalid && (
                  <FieldMessage id="firstName:invalid">
                    {t('createAccountForm.errorFirstname')}
                  </FieldMessage>
                )}
              </FormControlProvider>
            </FlexItem>
            <FlexItem>
              <FormControlProvider {...formState.lastName}>
                <Label
                  htmlFor="lastName"
                  className={formState.lastName.invalid ? 'invalid' : ''}
                >
                  {t('createAccountForm.lastname')}
                </Label>
                <Input
                  id="lastName"
                  name="lastName"
                  value={formFields.lastName}
                  onChange={handleInputChange}
                />
                {formState.lastName.invalid && (
                  <FieldMessage id="lastName:invalid">
                    {t('createAccountForm.errorLastname')}
                  </FieldMessage>
                )}
              </FormControlProvider>
            </FlexItem>
            <FlexItem>
              <FormControlProvider {...formState.country}>
                <Label
                  htmlFor="country"
                  className={formState.country.invalid ? 'invalid' : ''}
                >
                  {t('createAccountForm.countryOfResidence')}
                </Label>
                <Select
                  id="country"
                  name="country"
                  value={formFields.country}
                  onChange={handleSelectChange}
                  className="pando-input__root--size_lg create-account__form__select"
                >
                  <Option value="0" name="Select a country">
                    {t('createAccountForm.selectCountry')}
                  </Option>
                  {countries?.map((country) => (
                    <Option
                      key={country.id}
                      value={country.value}
                      name={country.name}
                    >
                      {country.name}
                    </Option>
                  ))}
                </Select>
                <Show when={formState.country.invalid}>
                  <FieldMessage id="country:invalid">
                    {t('createAccountForm.errorSelectCountry')}
                  </FieldMessage>
                </Show>
              </FormControlProvider>
            </FlexItem>
            <FlexItem>
              <FormControlProvider {...formState.acceptTerms}>
                <FlexContainer direction="row" align="flexStart">
                  <Checkbox
                    id="acceptTerms"
                    name="acceptTerms"
                    checked={formFields.acceptTerms}
                    onChange={handleInputChange}
                  />
                  <Label
                    htmlFor="acceptTerms"
                    className="create-account__form__terms"
                  >
                    {t('createAccountForm.disclaimer')}
                  </Label>
                </FlexContainer>
                {formState.acceptTerms.invalid && (
                  <FieldMessage id="acceptTerms:invalid">
                    {t('createAccountForm.acceptTerms')}
                  </FieldMessage>
                )}
              </FormControlProvider>
            </FlexItem>
          </FlexContainer>

          <FlexContainer className="create-account__body__actions">
            <Button
              disabled={isFormInvalid()}
              onClick={handleCreateAccount}
              aria-label="Submit form and create account"
            >
              {t('createAccountForm.createAccountButton')}
            </Button>
          </FlexContainer>
        </form>
      </FlexContainer>
    </FlexContainer>
  );
};

export default CreateAccount;
