import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LoginLayout from '@ps-fe-authentication/components/login/login-layout';
import ContainerComponent from '@ps-fe-authentication/components/container/container';
import Interests from '@ps-fe-authentication/pages/Interests';
import ProvidersLayer from '@ps-fe-authentication/components/ProvidersLayer';
import { ROUTES } from '@ps-fe-authentication/constants/routes';
import CreateAccountPage from '@ps-fe-authentication/pages/CreateAccount';
import AcceptInvitationPage from '@ps-fe-authentication/pages/AcceptInvitation';
import LoginPage from '@ps-fe-authentication/pages/Login';
import IssuePage from '@ps-fe-authentication/pages/issue-page/IssuePage';

import type { AppConfig } from '@ps-fe-authentication/contexts/AppConfigContext';
import type { CurrentUserData } from '@ps-fe-authentication/types/login';

export interface AuthenticationRootProps {
  user: CurrentUserData | null;
}

const AuthenticationRootComponent: React.FC<AuthenticationRootProps> = () => {
  const config = (window as unknown as { NEXTGEN_CONFIG: AppConfig })
    .NEXTGEN_CONFIG;

  return (
    <ProvidersLayer
      initialUser={null}
      initialLanguage={'en'}
      appConfig={config}
    >
      <BrowserRouter
        future={{
          v7_relativeSplatPath: true,
        }}
      >
        <Routes>
          <Route
            path={ROUTES.CREATE_ACCOUNT}
            element={<ContainerComponent children={<CreateAccountPage />} />}
          />
          <Route
            path={`${ROUTES.PLANS}/:planId/invites/:invitationId`}
            element={<ContainerComponent children={<AcceptInvitationPage />} />}
          />
          <Route
            path={ROUTES.LOGIN}
            element={
              <LoginLayout>
                <LoginPage />
              </LoginLayout>
            }
          />
          <Route path={ROUTES.INTERESTS} element={<Interests />} />
          <Route
            path={`${ROUTES.ISSUE}/:type?`}
            element={<ContainerComponent children={<IssuePage />} />}
          />
        </Routes>
      </BrowserRouter>
    </ProvidersLayer>
  );
};

export default AuthenticationRootComponent;
