import { Button, FlexContainer } from '@pluralsight/react-ng';

import WrenchIcon from '@ps-fe-authentication/assets/wrench.svg';
import ContentHeader from '@ps-fe-authentication/components/interests/content-header';
import './finish-screen.scss';
import { useTranslations } from '@ps-fe-authentication/contexts/TranslationsContext';

const FinishScreen = () => {
  const { t } = useTranslations();

  const handleGoToHome = () => {
    localStorage.setItem('interestsSelected', 'true');
    // TODO should be replaced with mfe-container nav method after integration
    window.location.href = '/';
  };

  return (
    <FlexContainer
      direction="col"
      align="center"
      gap="1rem"
      className="finish-screen"
    >
      <img src={WrenchIcon} alt="" role="presentation" />
      <ContentHeader
        title={t('interests.steps.finishScreen.title')}
        subtitle={t('interests.steps.finishScreen.subtitle')}
      />
      <Button
        aria-label={t('interests.home.ariaLabel')}
        palette="neutral"
        size="lg"
        usage="filled"
        role="link"
        className="interests-page__next"
        onClick={handleGoToHome}
      >
        {t('interests.home.text')}
      </Button>
    </FlexContainer>
  );
};

export default FinishScreen;
