// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stepper {
  padding-bottom: 1rem;
  width: 39.5rem;
}
.stepper__steps {
  width: 100%;
}
.stepper__steps .step-container {
  width: fit-content;
  height: fit-content;
  padding: 0.5rem;
}
.stepper__steps .step-number {
  color: var(--neutral-text-200, #A5AACF);
  font-size: var(--font-size-lg, 1.125rem);
  font-weight: 500;
  line-height: 150%;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: var(--radius-round, 624.9375rem);
  border: 2px solid var(--neutral-text-200, #A5AACF);
}
.stepper__steps .step-title {
  color: var(--neutral-text-200, #A5AACF);
  font-size: var(--font-size-lg, 1.125rem);
  font-weight: 700;
  line-height: 150%;
}
.stepper__steps .current {
  color: var(--neutral-text-300, #F3F3F8);
  border-color: var(--neutral-text-300, #F3F3F8);
}`, "",{"version":3,"sources":["webpack://./src/components/interests/stepper/stepper.scss"],"names":[],"mappings":"AACA;EACE,oBAAA;EACA,cAAA;AAAF;AAEE;EACE,WAAA;AAAJ;AAEI;EACE,kBAAA;EACA,mBAAA;EACA,eAAA;AAAN;AAGI;EACE,uCAAA;EACA,wCAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,cAAA;EACA,+CAAA;EACA,kDAAA;AADN;AAII;EACE,uCAAA;EACA,wCAAA;EACA,gBAAA;EACA,iBAAA;AAFN;AAKI;EACE,uCAAA;EACA,8CAAA;AAHN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.stepper {\n  padding-bottom: 1rem;\n  width: 39.5rem;\n\n  &__steps {\n    width: 100%;\n\n    .step-container {\n      width: fit-content;\n      height: fit-content;\n      padding: 0.5rem;\n    }\n\n    .step-number {\n      color: var(--neutral-text-200, #A5AACF);\n      font-size: var(--font-size-lg, 1.125rem);\n      font-weight: 500;\n      line-height: 150%;\n      width: 2.5rem;\n      height: 2.5rem;\n      border-radius: var(--radius-round, 624.9375rem);\n      border: 2px solid var(--neutral-text-200, #A5AACF);\n    }\n\n    .step-title {\n      color: var(--neutral-text-200, #A5AACF);\n      font-size: var(--font-size-lg, 1.125rem);\n      font-weight: 700;\n      line-height: 150%;\n    }\n\n    .current {\n      color: var(--neutral-text-300, #F3F3F8);\n      border-color: var(--neutral-text-300, #F3F3F8);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
