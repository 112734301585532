import httpClient from '@ps-fe-authentication/api/http-client';

import type {
  AcceptInvitationRequest,
  Country,
  Invitation,
  Plan,
} from '@ps-fe-authentication/types/create-account';

const API_VERSION = 'v1';
const PLANS_PATH = '/plans';

export const getPlanById = async (planId: string): Promise<Plan> => {
  try {
    const response = await httpClient.get<Plan>(
      `${API_VERSION}${PLANS_PATH}/${planId}`,
    );

    return response.data;
  } catch (error) {
    console.error('Error getting plan by id:', error);

    throw error;
  }
};

export const getCountries = async (): Promise<Country[]> => {
  try {
    const response = await httpClient.get<Country[]>('/countries');

    return response.data;
  } catch (error) {
    console.error('Error fetching countries:', error);

    throw error;
  }
};

export const getInvitation = async (
  planId: string,
  invitationId: string,
): Promise<Invitation | null> => {
  try {
    const response = await httpClient.get<Invitation | null>(
      `${API_VERSION}${PLANS_PATH}/${planId}/invites/${invitationId}`,
    );

    return response.data;
  } catch (error) {
    console.error('Error getting the invite:', error);

    throw error;
  }
};

export const acceptInvitation = async (
  request: AcceptInvitationRequest,
): Promise<string | null> => {
  try {
    const response = await httpClient.patch<string | null>(
      `${API_VERSION}${PLANS_PATH}/${request.planId}/invites/${request.invitationId}`,
      {
        status: request.invitationStatus,
      },
    );

    return response.data;
  } catch (error) {
    console.error('Error updating the invite:', error);

    throw error;
  }
};
