import React, { createContext, useState, useEffect, useContext } from 'react';

import localConfig from '@ps-fe-authentication/configs/config.local.json';
import httpClient from '@ps-fe-authentication/api/http-client';

import type { FC, PropsWithChildren } from 'react';

export type AppConfig = typeof localConfig;

const AppConfigContext = createContext<AppConfig | undefined>(undefined);

interface Props {
  appConfig: AppConfig | null;
}

export const AppConfigProvider: FC<PropsWithChildren<Props>> = ({
  children,
  appConfig,
}) => {
  const [config, setConfig] = useState<AppConfig | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const isLocal = window.location.origin.includes('localhost');

    if (isLocal) {
      setConfig(localConfig);
    } else {
      if (appConfig) {
        setConfig(appConfig);
      } else {
        setError(new Error(`App config could not be found.`));
      }
    }
  }, []);

  if (config) {
    httpClient.defaults.baseURL = config.COMMON.API.PLANS.BASE_URL;
  }

  if (error) {
    return <div>Error loading app config: {error.message}</div>;
  }

  if (!config) {
    return <div>Loading config...</div>;
  }

  return (
    <AppConfigContext.Provider value={config}>
      {children}
    </AppConfigContext.Provider>
  );
};

export const useAppConfig = (): AppConfig => {
  const context = useContext(AppConfigContext);

  if (context === undefined) {
    throw new Error('useAppConfig must be used within an AppConfigProvider');
  }

  return context;
};
