import httpClient from '@ps-fe-authentication/api/http-client';

import type {
  CreateAccountData,
  UserAccount,
  UserProfileInfo,
  UserProfileInfoResponseItem,
  CurrentUserData,
  UserAppData,
} from '@ps-fe-authentication/types';

const API_VERSION = 'v1';
const USERS_PATH = '/users';

export const getCurrentUser = async () => {
  try {
    const response = await httpClient.get<CurrentUserData>(
      `${API_VERSION}${USERS_PATH}/current`,
    );

    return response.data;
  } catch (error) {
    console.error('Error getting current user:', error);

    throw error;
  }
};

export const getAppUserData: () => Promise<UserAppData | null> = async () => {
  try {
    const { data } = await httpClient.get<CurrentUserData>(
      `${API_VERSION}${USERS_PATH}/current`,
    );

    const appMetadata = {
      country: data.country,
      psId: data.psUserId,
      terms_accepted: data.isTermsAccepted,
      terms_accepted_created_at: data.acceptedTermsOn,
    };

    return {
      userId: data.psUserId,
      username: `${data.firstName} ${data.lastName}`,
      email: data.email,
      appMetadata,
    };
  } catch (err) {
    console.log(err);

    return null;
  }
};

export const getUserByPsId = async (psId: string): Promise<UserAccount> => {
  try {
    const response = await httpClient.get<UserAccount>(
      `${API_VERSION}${USERS_PATH}/${psId}`,
    );

    return response.data;
  } catch (error) {
    console.error('Error getting user by :', error);

    throw error;
  }
};

export const postAccount = async (
  psUserId: string,
  request: CreateAccountData,
): Promise<UserAccount> => {
  try {
    const response = await httpClient.patch<UserAccount>(
      `${API_VERSION}${USERS_PATH}/${psUserId}`,
      request,
    );

    return response.data;
  } catch (error) {
    console.error('Error creation account:', error);

    throw error;
  }
};

export const patchUserProfileInfo = async (
  psId: string,
  patch: Partial<UserProfileInfo>,
): Promise<UserProfileInfoResponseItem> => {
  try {
    const response = await httpClient.patch(
      `${API_VERSION}${USERS_PATH}/${psId}/profile-info`,
      patch,
    );

    return response.data;
  } catch (error) {
    console.error('Error patching user profile info:', error);

    throw error;
  }
};

type CreateAccount = (payload: {
  data: CreateAccountData;
  psId: string;
}) => Promise<UserProfileInfoResponseItem>;

export const createAccount: CreateAccount = async ({ psId, data }) => {
  await postAccount(psId, data);

  return await patchUserProfileInfo(psId, {
    nickname: `${data.firstName} ${data.lastName}`,
  });
};
