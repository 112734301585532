import {
  Button,
  FlexContainer,
  FlexItem,
  useToast,
} from '@pluralsight/react-ng';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import HeaderIcon from '@ps-fe-authentication/assets/header.svg';
import { InvitationStatus } from '@ps-fe-authentication/types/create-account';
import { useTranslations } from '@ps-fe-authentication/contexts/TranslationsContext';
import {
  useInvitationQuery,
  usePlanByIdQuery,
} from '@ps-fe-authentication/api/queries';
import { useAcceptInvitationMutation } from '@ps-fe-authentication/api/mutations';
import DeclineInvitationDialog from '@ps-fe-authentication/components/create-account/decline-invitation-dialog';
import { ERRORS } from '@ps-fe-authentication/constants';

import type { Params } from '@ps-fe-authentication/types';

import './accept-invitation.scss';

const { EXPIRED_INVITATION } = ERRORS;
const TOAST_DURATION = 8000;

const AcceptInvitation: React.FC = () => {
  const { t } = useTranslations();
  const { show } = useToast();

  const [dialogStep, setDialogStep] = useState<'0' | '1' | '2'>('0');

  const { planId = '', invitationId = '' } = useParams<Params>();
  const declineDialogRef = useRef<HTMLDialogElement>(null);

  const { data: invitation } = useInvitationQuery({ planId, invitationId });
  const { data: plan } = usePlanByIdQuery({ planId });

  const { mutate: acceptInvitation } = useAcceptInvitationMutation({
    onError: () => {
      window.location.href = `/create-account?error=${EXPIRED_INVITATION}`;
    },
    onSuccess: () => {
      show({
        heading: t('acceptInvitation.invitationAccepted'),
        text: t('acceptInvitation.invitationAcceptedMessage', {
          planName: plan?.displayName,
        }),
        palette: 'info',
        duration: TOAST_DURATION,
        onClose: (): void => {},
      });
    },
  });

  const handleDecline = () => {
    show({
      heading: t('acceptInvitation.invitationDeclined'),
      text: t('acceptInvitation.invitationDeclinedMessage', {
        planName: plan?.displayName,
      }),
      palette: 'info',
      duration: TOAST_DURATION,
      onClose: (): void => {},
    });

    // TODO should be replaced with mfe-container nav method after integration
    window.location.href = '/';
  };

  if (!invitation || !plan) {
    return null;
  }

  const openDeclineDialog = () => {
    declineDialogRef.current?.showModal();
    setDialogStep('1');
  };

  const closeDeclineDialog = () => {
    declineDialogRef.current?.close();
    setDialogStep('0');
  };

  const handleAcceptInvitation = () => {
    acceptInvitation({
      planId: planId as string,
      invitationId: invitationId as string,
      invitationStatus: InvitationStatus.Accepted,
    });
  };

  return (
    <>
      <FlexContainer className="accept-invitation__main">
        <FlexContainer className="accept-invitation__header">
          <FlexItem>
            <img
              src={HeaderIcon}
              alt=""
              role="presentation"
              className="accept-invitation_header__logo"
            />
          </FlexItem>
          <FlexItem>
            <h1 className="accept-invitation__header__title">
              {t('acceptInvitation.title', { planName: plan?.displayName })}
            </h1>
          </FlexItem>
        </FlexContainer>
        <FlexItem className="accept-invitation__body">
          <p className="accept-invitation__text">
            {t('acceptInvitation.description', { planName: plan?.displayName })}
          </p>
        </FlexItem>
        <FlexContainer className="accept-invitation__footer">
          <Button
            onClick={openDeclineDialog}
            type="button"
            palette="action"
            usage="outline"
          >
            {t('acceptInvitation.decline')}
          </Button>
          <Button
            onClick={handleAcceptInvitation}
            type="button"
            palette="action"
          >
            {t('acceptInvitation.accept')}
          </Button>
        </FlexContainer>
      </FlexContainer>

      <DeclineInvitationDialog
        ref={declineDialogRef}
        handleDecline={handleDecline}
        step={dialogStep}
        closeModal={closeDeclineDialog}
        planName={plan?.displayName}
      />
    </>
  );
};

export default AcceptInvitation;
