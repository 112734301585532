// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-header {
  text-align: center;
}
.content-header__title {
  color: var(--pando-colors-neutral-text-300, #f3f3f8);
  font-family: var(--font-pando-title, "PS TT Commons"), serif;
  font-size: var(--pando-font-size-4xl, 2.5rem);
  font-weight: 600;
  line-height: 110%;
}
.content-header__subtitle {
  color: var(--pando-colors-neutral-text-200, #a5aacf);
  font-family: var(--font-pando-body, "PS TT Commons"), serif;
  font-size: var(--pando-font-size-md, 1rem);
  font-weight: 500;
  line-height: 150%;
}`, "",{"version":3,"sources":["webpack://./src/components/interests/content-header/content-header.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;AAAF;AAEE;EACE,oDAAA;EACA,4DAAA;EACA,6CAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAGE;EACE,oDAAA;EACA,2DAAA;EACA,0CAAA;EACA,gBAAA;EACA,iBAAA;AADJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.content-header {\n  text-align: center;\n\n  &__title {\n    color: var(--pando-colors-neutral-text-300, #f3f3f8);\n    font-family: var(--font-pando-title, \"PS TT Commons\"), serif;\n    font-size: var(--pando-font-size-4xl, 2.5rem);\n    font-weight: 600;\n    line-height: 110%;\n  }\n\n  &__subtitle {\n    color: var(--pando-colors-neutral-text-200, #a5aacf);\n    font-family: var(--font-pando-body, \"PS TT Commons\"), serif;\n    font-size: var(--pando-font-size-md, 1rem);\n    font-weight: 500;\n    line-height: 150%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
