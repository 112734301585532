// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.categories-grid {
  gap: 1rem;
  max-width: 30.5rem;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(9.5rem, max-content));
}`, "",{"version":3,"sources":["webpack://./src/components/interests/steps/categories/categories.scss"],"names":[],"mappings":"AACA;EACE,SAAA;EACA,kBAAA;EACA,uBAAA;EACA,oEAAA;AAAF","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.categories-grid {\n  gap: 1rem;\n  max-width: calc(9.5rem * 3 + 2rem);\n  justify-content: center;\n  grid-template-columns: repeat(auto-fit, minmax(9.5rem, max-content));\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
