import { FlexContainer, useToast } from '@pluralsight/react-ng';

import PluralsightLogo from '@ps-fe-authentication/assets/logo.svg';
import PluralsightHeader from '@ps-fe-authentication/assets/pluralsight.svg';
import SelectLanguage from '@ps-fe-authentication/components/select-language';
import { useTranslations } from '@ps-fe-authentication/contexts/TranslationsContext';
import { LANGUAGE_OPTIONS } from '@ps-fe-authentication/constants/language-options';

import './container.scss';

import type { FC, PropsWithChildren } from 'react';

const ContainerComponent: FC<PropsWithChildren> = ({ children }) => {
  const { t, setLanguage, language } = useTranslations();
  const { show } = useToast();
  const currentYear = new Date().getFullYear();

  const changeLanguage = async (selectedLanguage: string) => {
    try {
      await setLanguage(selectedLanguage);
      show({
        text: t('toastLanguageChange.textSucessfully'),
        palette: 'success',
        duration: 2000,
        onClose: (): void => {},
      });
    } catch (err) {
      console.error('Error changing language:', err);
      show({
        text: t('toastLanguageChange.textUnsucessfully'),
        palette: 'danger',
        duration: 2000,
        onClose: (): void => {},
      });
    }
  };

  return (
    <FlexContainer className="base-container">
      <FlexContainer className="base-container__header">
        <FlexContainer justify="center">
          <img
            className="base-container__header__logo"
            src={PluralsightHeader}
            alt="Pluraslsight Logo"
          />
        </FlexContainer>
      </FlexContainer>
      <FlexContainer justify={'center'} className="base-container__body">
        {children}
      </FlexContainer>
      <FlexContainer className="base-container__footer">
        <div className="base-container__footer__copyright">
          <img src={PluralsightLogo} alt="" role="presentation" />
          <div className="base-container__footer__copyright__text">
            <span>{t('container.copyright1', { currentYear })} </span>
            <span>{t('container.copyright2')}</span>
          </div>
        </div>
        <div className="base-container__footer__links">
          <a href="https://legal.pluralsight.com/policies">
            {t('container.termsOfUse')}
          </a>
          <a href="https://legal.pluralsight.com/policies?name=privacy-notice">
            {t('container.privacyPolicy')}
          </a>
          <SelectLanguage
            languageOptions={LANGUAGE_OPTIONS}
            language={language}
            changeLanguage={(val) => {
              void changeLanguage(val);
            }}
          />
        </div>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ContainerComponent;
