import {
  FormControlProvider,
  Checkbox,
  FlexContainer,
} from '@pluralsight/react-ng';

import { useSelectedInterests } from '@ps-fe-authentication/contexts/InterestsContext';

import type { IInterest } from '@ps-fe-authentication/types/interests';
import type { FC } from 'react';

interface ISearchResultItem {
  interest: IInterest;
}

const SearchResultItem: FC<ISearchResultItem> = ({ interest }) => {
  const { selectedInterests, toggleInterest } = useSelectedInterests();

  const isChecked = selectedInterests.some(
    (item: IInterest) => item.id === interest.id,
  );

  return (
    <FlexContainer>
      <FormControlProvider>
        <Checkbox
          id={interest.id}
          name={interest.id}
          checked={isChecked}
          onChange={() => toggleInterest(interest)}
        >
          <span className="popover-item__text">{interest.name}</span>
        </Checkbox>
      </FormControlProvider>
    </FlexContainer>
  );
};

export default SearchResultItem;
