import { FlexContainer, FlexItem } from '@pluralsight/react-ng';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import './login-layout.scss';

import SelectLanguage from '@ps-fe-authentication/components/select-language/select-language';
import { useTranslations } from '@ps-fe-authentication/contexts/TranslationsContext';
import { LANGUAGE_OPTIONS } from '@ps-fe-authentication/constants/language-options';

import type { FC, PropsWithChildren } from 'react';

const LoginLayout: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();

  const { t, setLanguage, language } = useTranslations();
  const currentYear = new Date().getFullYear();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <FlexContainer className="login">
      <FlexContainer
        align="center"
        style={{
          maxWidth: '1080px',
          padding: '20px 0',
          borderBottom: '1px solid var(--neutral-border-100, #2D2D55)',
        }}
        role="Header"
      >
        <FlexItem>
          <FlexContainer
            tabIndex={0}
            onClick={handleBack}
            align="center"
            role="link"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M5.56907 7.76425L8.43099 4.90232C8.56117 4.77214 8.77222 4.77214 8.9024 4.90232L9.37132 5.37124C9.50137 5.50129 9.50152 5.71209 9.37165 5.84232L7.22003 7.99995L9.37165 10.1576C9.50152 10.2878 9.50137 10.4986 9.37132 10.6287L8.9024 11.0976C8.77222 11.2278 8.56117 11.2278 8.43099 11.0976L5.56907 8.23565C5.43889 8.10548 5.43889 7.89442 5.56907 7.76425Z"
                fill="#3895FF"
              />
            </svg>
            <span
              style={{
                color: 'var(--action-navigation-initial, #3895FF)',
                fontFamily: 'var(--font-family-body, "PS TT Commons")',
                fontSize: 'var(--font-size-sm, 14px)',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '150%',
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }}
            >
              {t('actionButtons.backButton')}
            </span>
          </FlexContainer>
        </FlexItem>
        <FlexContainer justify="center" style={{ paddingRight: '48px' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="174"
            height="32"
            viewBox="0 0 174 32"
            fill="none"
            role="img"
            aria-label="Logo Pluralsight"
          >
            <title>Logo Pluralsight</title>
            <path
              d="M31.3291 17.5171C32.167 9.51844 26.362 2.355 18.3633 1.51713C10.3646 0.679254 3.20117 6.48424 2.36329 14.4829C1.52542 22.4816 7.33041 29.6451 15.3291 30.4829C23.3278 31.3208 30.4912 25.5158 31.3291 17.5171Z"
              fill="url(#paint0_linear_2377_906)"
            />
            <path
              d="M9.83081 22.4053L13.0364 20.5542V24.1627L27.1748 16.0012L13.0364 7.83972V11.4481L9.83081 9.59704V22.4053ZM14.5019 13.9869L17.9923 16.0012L14.5019 18.0155V13.9869ZM14.5019 19.7103L14.5436 19.686L20.9235 16.0012L14.5019 12.2921V10.3785L24.2401 16.0012L14.5019 21.6239V19.7103ZM11.2964 12.1358L13.0329 13.1395V18.8629L11.2964 19.8666V12.1358Z"
              fill="white"
            />
            <path
              d="M39.6497 10.8856H43.8901C46.3664 10.8856 47.8632 12.3546 47.8632 14.4731V14.5044C47.8632 16.9077 45.9948 18.151 43.6679 18.151H41.9349V21.2662H39.6531V10.8856H39.6497ZM43.7408 16.1193C44.8834 16.1193 45.5502 15.4386 45.5502 14.5461V14.5148C45.5502 13.4903 44.8383 12.9416 43.6957 12.9416H41.9314V16.1158H43.7408V16.1193Z"
              fill="white"
            />
            <path
              d="M52.2217 10.8856H54.5035V19.1894H59.6782V21.2662H52.2183V10.8856H52.2217Z"
              fill="white"
            />
            <path
              d="M63.6096 16.8174V10.8856H65.8914V16.7583C65.8914 18.4497 66.7353 19.3249 68.1314 19.3249C69.5276 19.3249 70.3715 18.4809 70.3715 16.8347V10.889H72.6567V16.7445C72.6567 19.8875 70.8924 21.4295 68.1036 21.4295C65.3148 21.4295 63.6096 19.8736 63.6096 16.8174Z"
              fill="white"
            />
            <path
              d="M77.6472 10.8856H82.3913C83.711 10.8856 84.7355 11.2572 85.4162 11.9379C85.9928 12.5179 86.3053 13.3305 86.3053 14.3099V14.3412C86.3053 16.0151 85.4023 17.0709 84.0826 17.5606L86.6179 21.2662H83.9506L81.728 17.9461H79.9324V21.2662H77.6507V10.8856H77.6472ZM82.2454 15.9283C83.3568 15.9283 83.9958 15.3344 83.9958 14.4592V14.428C83.9958 13.4486 83.3151 12.945 82.2003 12.945H79.9324V15.9248H82.2454V15.9283Z"
              fill="white"
            />
            <path
              d="M94.5919 10.8126H96.6965L101.145 21.2662H98.7594L97.8113 18.9394H93.4215L92.4734 21.2662H90.1465L94.5953 10.8126H94.5919ZM96.9952 16.9216L95.6164 13.5563L94.2376 16.9216H96.9952Z"
              fill="white"
            />
            <path
              d="M105.417 10.8856H107.699V19.1894H112.873V21.2662H105.414V10.8856H105.417Z"
              fill="white"
            />
            <path
              d="M116.548 19.7555L117.899 18.1406C118.833 18.9116 119.813 19.4013 120.997 19.4013C121.931 19.4013 122.494 19.0297 122.494 18.4219V18.3941C122.494 17.8141 122.14 17.5189 120.403 17.0744C118.312 16.5395 116.961 15.963 116.961 13.9001V13.8688C116.961 11.9865 118.476 10.7397 120.594 10.7397C122.108 10.7397 123.397 11.2155 124.449 12.0594L123.261 13.7785C122.341 13.1395 121.438 12.754 120.563 12.754C119.688 12.754 119.229 13.1534 119.229 13.657V13.6882C119.229 14.3689 119.674 14.5912 121.469 15.0531C123.574 15.6018 124.762 16.3589 124.762 18.1684V18.1996C124.762 20.2591 123.188 21.4156 120.952 21.4156C119.382 21.4156 117.795 20.8668 116.548 19.7555Z"
              fill="white"
            />
            <path
              d="M129.523 10.8856H131.805V21.2662H129.523V10.8856Z"
              fill="white"
            />
            <path
              d="M136.608 16.1054V16.0742C136.608 13.1221 138.907 10.705 142.05 10.705C143.919 10.705 145.044 11.2085 146.127 12.1289L144.69 13.8619C143.887 13.1951 143.179 12.8096 141.977 12.8096C140.317 12.8096 138.997 14.2786 138.997 16.0429V16.0742C138.997 17.9739 140.303 19.3665 142.14 19.3665C142.97 19.3665 143.714 19.1582 144.29 18.7449V17.2619H141.991V15.2893H146.499V19.7972C145.433 20.7001 143.964 21.4434 142.064 21.4434C138.831 21.4434 136.608 19.1755 136.608 16.1054Z"
              fill="white"
            />
            <path
              d="M151.361 10.8856H153.643V14.9941H157.856V10.8856H160.137V21.2662H157.856V17.0987H153.643V21.2662H151.361V10.8856Z"
              fill="white"
            />
            <path
              d="M167.712 12.9937H164.555V10.889H173.154V12.9937H169.997V21.2663H167.715V12.9937H167.712Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2377_906"
                x1="16.8461"
                y1="1.91143"
                x2="16.8461"
                y2="30.7301"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF1675" />
                <stop offset="0.15" stop-color="#FF2C5B" />
                <stop offset="0.4" stop-color="#FF4E34" />
                <stop offset="0.64" stop-color="#FF6618" />
                <stop offset="0.85" stop-color="#FF7507" />
                <stop offset="1" stop-color="#FF7B01" />
              </linearGradient>
            </defs>
          </svg>
        </FlexContainer>
      </FlexContainer>
      <FlexItem
        style={{ width: '356px', margin: '120px 0 252px' }}
        role="Content"
      >
        <FlexContainer direction="col" gap="40px">
          <h1
            style={{
              color: 'var(--neutral-text-300, #F3F3F8)',
              fontFamily: 'var(--font-family-title, "PS TT Commons")',
              fontSize: 'var(--font-size-2xl, 24px)',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '125%',
            }}
          >
            {t('form.signIn')}
          </h1>
          {children}
          <FlexContainer gap="8px">
            <span
              style={{
                color: 'var(--neutral-text-200, #A5AACF)',
                fontFamily: 'var(--font-family-body, "PS TT Commons")',
                fontSize: 'var(--font-size-sm, 14px)',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '150%',
              }}
            >
              {t('form.newInPluralsight')}
            </span>
            <a
              tabIndex={8}
              role="link"
              href="https://www.pluralsight.com/get-started"
              target="_blank"
              style={{
                color: 'var(--action-navigation-initial, #3895FF)',
                fontFamily: 'var(--font-family-body, "PS TT Commons")',
                fontSize: 'var(--font-size-sm, 14px)',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '150%',
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }}
            >
              {t('form.createYourAccount')}
            </a>
          </FlexContainer>
        </FlexContainer>
      </FlexItem>
      <FlexContainer
        align="stretch"
        direction="row"
        justify="spaceBetween"
        wrap="nowrap"
        style={{ maxWidth: '1080px', margin: '0 auto' }}
      >
        <FlexContainer align="center" gap="16px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            role="img"
            aria-label="Logo Pluralsight"
          >
            <title>Logo Pluralsight</title>
            <path
              d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z"
              fill="url(#paint0_linear_551_1140)"
            />
            <path
              d="M6.21887 17.278L8.85959 15.7538V18.727L20.5104 12.0013L8.85959 5.27557V8.24757L6.21887 6.72344V17.2791V17.278ZM10.0679 10.3389L12.9434 11.9989L10.0679 13.6601V10.3389ZM10.0679 15.0555L10.1025 15.0353L15.3601 12.0001L10.0679 8.94469V7.36693L18.0926 12.0001L10.0679 16.6333V15.0555ZM7.42721 8.8148L8.85959 9.64181V14.3584L7.42721 15.1854V8.8148Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_551_1140"
                x1="10.7908"
                y1="0.452967"
                x2="13.2648"
                y2="24.0711"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FF1675" />
                <stop offset="0.15" stop-color="#FF2C5B" />
                <stop offset="0.4" stop-color="#FF4E34" />
                <stop offset="0.64" stop-color="#FF6618" />
                <stop offset="0.85" stop-color="#FF7507" />
                <stop offset="1" stop-color="#FF7B01" />
              </linearGradient>
            </defs>
          </svg>
          <span
            style={{
              color:
                'var(--Tertiary-Dark-text-weak, var(--neutral-text-100, #8B90C1))',
              fontFamily: '"PS TT Commons"',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '20px',
            }}
          >
            {t('footer.copyright', { currentYear })}
          </span>
        </FlexContainer>
        <FlexContainer
          align="stretch"
          direction="row"
          justify="flexEnd"
          wrap="nowrap"
          gap="40px"
        >
          <span
            tabIndex={8}
            role="link"
            style={{
              color:
                'var(--Tertiary-Dark-text-weak, var(--neutral-text-100, #8B90C1))',
              fontFamily: '"PS TT Commons"',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '20px',
              textDecorationLine: 'underline',
              cursor: 'pointer',
            }}
          >
            {t('footer.termsOfUse')}
          </span>
          <span
            tabIndex={9}
            role="link"
            style={{
              color:
                'var(--Tertiary-Dark-text-weak, var(--neutral-text-100, #8B90C1))',
              fontFamily: '"PS TT Commons"',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '20px',
              textDecorationLine: 'underline',
              cursor: 'pointer',
            }}
          >
            {t('footer.privacyPolicy')}
          </span>
          <span>
            <SelectLanguage
              languageOptions={LANGUAGE_OPTIONS}
              language={language}
              changeLanguage={(lang) => {
                void setLanguage(lang);
              }}
            />
          </span>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default LoginLayout;
