// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.decline-invitation-dialog {
  padding: 0 var(--pando-spacing-6) var(--pando-spacing-6);
  position: relative;
  width: var(--pando-sizes-sm);
}
.decline-invitation-dialog__content {
  margin: var(--pando-spacing-3) 0;
}
.decline-invitation-dialog__content__description {
  margin-bottom: var(--pando-spacing-6);
}
.decline-invitation-dialog__status-section {
  width: fit-content;
  height: var(--pando-spacing-10);
  position: absolute;
  top: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/create-account/decline-invitation-dialog/decline-invitation-dialog.scss"],"names":[],"mappings":"AACA;EACE,wDAAA;EACA,kBAAA;EACA,4BAAA;AAAF;AAEE;EACE,gCAAA;AAAJ;AAEI;EACE,qCAAA;AAAN;AAIE;EACE,kBAAA;EACA,+BAAA;EACA,kBAAA;EACA,MAAA;AAFJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.decline-invitation-dialog {\n  padding: 0 var(--pando-spacing-6) var(--pando-spacing-6);\n  position: relative;\n  width: var(--pando-sizes-sm);\n\n  &__content {\n    margin: var(--pando-spacing-3) 0;\n\n    &__description {\n      margin-bottom: var(--pando-spacing-6);\n    }\n  }\n\n  &__status-section {\n    width: fit-content;\n    height: var(--pando-spacing-10);\n    position: absolute;\n    top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
