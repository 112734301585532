import { useSearchParams } from 'react-router-dom';

import CreateAccount from '@ps-fe-authentication/components/create-account/create-account';
import InvitationExpired from '@ps-fe-authentication/components/create-account/invitation-expired';

import type { FC } from 'react';

const CreateAccountPage: FC = () => {
  const [searchParams] = useSearchParams();

  const errorFromQuery = searchParams.get('error');

  return errorFromQuery ? <InvitationExpired /> : <CreateAccount />;
};

export default CreateAccountPage;
