// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-dialog-authentication {
  max-width: 726px;
  padding: 0 var(--pando-spacing-6) var(--pando-spacing-6);
  position: relative;
  width: var(--pando-sizes-sm);
  /* pando overrides */
}
.custom-dialog-authentication__warning {
  color: var(--pando-colors-danger-border-initial);
  height: 24px;
  width: 24px;
}
.custom-dialog-authentication__status-section {
  width: fit-content;
  height: var(--pando-spacing-10);
  position: absolute;
  top: 0;
}
.custom-dialog-authentication__status-section-step {
  font-size: var(--pando-font-size-sm, 14px);
  line-height: 100%;
}
.custom-dialog-authentication header {
  width: fit-content;
  color: var(--pando-colors-neutral-text-300);
}
.custom-dialog-authentication .pando-alertDialog__closeButton {
  height: var(--pando-spacing-5);
  width: var(--pando-spacing-5);
  margin: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/custom-dialog/custom-dialog.scss"],"names":[],"mappings":"AACA;EACE,gBAAA;EACA,wDAAA;EACA,kBAAA;EACA,4BAAA;EAyBA,oBAAA;AAxBF;AACE;EACE,gDAAA;EACA,YAAA;EACA,WAAA;AACJ;AAEE;EACE,kBAAA;EACA,+BAAA;EACA,kBAAA;EACA,MAAA;AAAJ;AAGE;EACE,0CAAA;EACA,iBAAA;AADJ;AAIE;EACE,kBAAA;EACA,2CAAA;AAFJ;AAME;EACE,8BAAA;EACA,6BAAA;EACA,YAAA;AAJJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.custom-dialog-authentication {\n  max-width: 726px;\n  padding: 0 var(--pando-spacing-6) var(--pando-spacing-6);\n  position: relative;\n  width: var(--pando-sizes-sm);\n\n  &__warning {\n    color: var(--pando-colors-danger-border-initial);\n    height: 24px;\n    width: 24px;\n  }\n\n  &__status-section {\n    width: fit-content;\n    height: var(--pando-spacing-10);\n    position: absolute;\n    top: 0;\n  }\n\n  &__status-section-step {\n    font-size: var(--pando-font-size-sm, 14px);\n    line-height: 100%;\n  }\n\n  header {\n    width: fit-content;\n    color: var(--pando-colors-neutral-text-300);\n  }\n\n  /* pando overrides */\n  .pando-alertDialog__closeButton {\n    height: var(--pando-spacing-5);\n    width: var(--pando-spacing-5);\n    margin: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
