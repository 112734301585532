import { FlexContainer, GridContainer } from '@pluralsight/react-ng';
import { useMemo } from 'react';

import { useSelectedCategories } from '@ps-fe-authentication/contexts/InterestsContext';
import SearchInterests from '@ps-fe-authentication/components/interests/steps/interests/search/search-interests';
import InterestCategory from '@ps-fe-authentication/components/interests/steps/interests/interest-category/interest-category';
import TagList from '@ps-fe-authentication/components/interests/steps/interests/tag-list/tag-list';
import { categories } from '@ps-fe-authentication/components/interests/steps/categories/constants';
import './interests.scss';

import type { ICategory } from '@ps-fe-authentication/components/interests/steps/categories/constants';

const Interests = () => {
  const { selectedCategories } = useSelectedCategories();

  const selectedCategoriesFiltered = useMemo(
    () =>
      categories.filter((category: ICategory) =>
        selectedCategories.includes(category.id),
      ),
    [selectedCategories],
  );

  return (
    <FlexContainer
      className="interests"
      align="center"
      justify="flexStart"
      direction="col"
      gap="1rem"
    >
      <SearchInterests />
      <TagList />
      <GridContainer className="interests__categories-grid">
        {selectedCategoriesFiltered.map((category) => (
          <InterestCategory key={category.id} category={category} />
        ))}
      </GridContainer>
    </FlexContainer>
  );
};

export default Interests;
