import { useEffect } from 'react';

export enum CUSTOM_EVENTS {
  USER_UPDATED = 'USER_UPDATED',
  LANGUAGE_UPDATED = 'LANGUAGE_UPDATED',
}

export const APP_IDENTIFIER = 'PS-FE-AUTHENTICATION';

interface Props<T> {
  event: string;
  callback: (data: T) => void;
}

export interface CustomEventDetail<T = unknown> {
  data: T;
  source: string;
}

export const useCustomEvent = <T = unknown>(props: Props<T>) => {
  const eventCallback: EventListener = (e) => {
    const event = e as CustomEvent<CustomEventDetail<T>>;

    if (event.detail.source !== APP_IDENTIFIER) {
      props.callback(event.detail.data);
    }
  };

  useEffect(() => {
    window.addEventListener(props.event, eventCallback);

    return () => {
      window.removeEventListener(props.event, eventCallback);
    };
  }, []);

  return {
    sendCustomEvent,
  };
};

export const sendCustomEvent = (name: CUSTOM_EVENTS, data?: unknown) => {
  const event = new CustomEvent(name, {
    detail: { data, source: APP_IDENTIFIER },
  });

  window.dispatchEvent(event);
};
