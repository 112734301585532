import React, { useState, useCallback } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

import { deepCopy } from '@ps-fe-authentication/utils/deep-copy';

import type {
  IInterest,
  ISelectedInterest,
  TProficiencyLevel,
} from '@ps-fe-authentication/types';

type InterestsContextType = {
  currentStep: number;
  selectedInterests: ISelectedInterest[];
  selectedCategories: number[];
  toggleInterest: (interest: IInterest) => void;
  toggleCategory: (id: number) => void;
  setPreviousStep: () => void;
  setNextStep: () => void;
  setInterestProficiency: (data: {
    id: string;
    proficiency: TProficiencyLevel;
  }) => void;
};

const InterestsContext = createContext<InterestsContextType | undefined>(
  undefined,
);

export const InterestsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [selectedInterests, setSelectedInterests] = useState<
    ISelectedInterest[]
  >([]);
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [currentStep, setCurrentStep] = useState(1);

  const toggleInterest = useCallback((interest: IInterest) => {
    setSelectedInterests((prev) => {
      const index = prev.findIndex((item) => item.id === interest.id);

      if (index !== -1) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      } else {
        return [{ ...interest, proficiency: null }, ...prev];
      }
    });
  }, []);

  const toggleCategory = useCallback((id: number) => {
    setSelectedCategories((prev) =>
      prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id],
    );
  }, []);

  const setInterestProficiency = (data: {
    id: string;
    proficiency: TProficiencyLevel;
  }) => {
    const { id, proficiency } = data;

    setSelectedInterests((prev) => {
      const interests = deepCopy(prev);

      const index = interests.findIndex((item) => item.id === id);

      if (index !== -1) {
        interests[index].proficiency = proficiency;
      }

      return interests;
    });
  };

  const setPreviousStep = () => {
    setCurrentStep((prev) => {
      if (currentStep > 1) {
        return prev - 1;
      }

      return prev;
    });
  };

  const setNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <InterestsContext.Provider
      value={{
        currentStep,
        selectedInterests,
        selectedCategories,
        setPreviousStep,
        setNextStep,
        setInterestProficiency,
        toggleInterest,
        toggleCategory,
      }}
    >
      {children}
    </InterestsContext.Provider>
  );
};

export const useCurrentStep = () =>
  useContextSelector(InterestsContext, (context) => {
    if (!context)
      throw new Error('useCurrentStep must be used within a InterestsProvider');

    return {
      currentStep: context.currentStep,
      setNextStep: context.setNextStep,
      setPreviousStep: context.setPreviousStep,
    };
  });

export const useSelectedInterests = () =>
  useContextSelector(InterestsContext, (context) => {
    if (!context)
      throw new Error(
        'useSelectedInterests must be used within a InterestsProvider',
      );

    return {
      selectedInterests: context.selectedInterests,
      toggleInterest: context.toggleInterest,
      setInterestProficiency: context.setInterestProficiency,
    };
  });

export const useSelectedCategories = () =>
  useContextSelector(InterestsContext, (context) => {
    if (!context)
      throw new Error(
        'useSelectedCategories must be used within a InterestsProvider',
      );

    return {
      selectedCategories: context.selectedCategories,
      toggleCategory: context.toggleCategory,
    };
  });
