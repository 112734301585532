import { ChevronLeftIcon } from '@pluralsight/icons';
import { Button, FlexContainer, Show } from '@pluralsight/react-ng';

import SkillsIcon from '@ps-fe-authentication/assets/skills-icon.svg';
import SkillsText from '@ps-fe-authentication/assets/skills-text.svg';
import { useTranslations } from '@ps-fe-authentication/contexts/TranslationsContext';
import {
  useCurrentStep,
  useSelectedCategories,
  useSelectedInterests,
} from '@ps-fe-authentication/contexts/InterestsContext';
import FinishScreen from '@ps-fe-authentication/components/interests/finish-screen';
import Stepper from '@ps-fe-authentication/components/interests/stepper';

import './interests-page.scss';

const Interests = () => {
  const { t } = useTranslations();

  const { selectedInterests } = useSelectedInterests();
  const { selectedCategories } = useSelectedCategories();
  const { currentStep, setNextStep, setPreviousStep } = useCurrentStep();

  const isOneCategorySelected = selectedCategories.length > 0;
  const isThreeInterestsSelected = selectedInterests.length >= 3;

  const isNextButtonEnabled =
    (currentStep === 1 && isOneCategorySelected) ||
    (currentStep > 1 && isThreeInterestsSelected);

  const handleSkipForNow = () => {
    localStorage.setItem('interestsSelected', 'true');
    // TODO should be replaced with mfe-container nav method after integration
    window.location.href = '/';
  };

  return (
    <FlexContainer className="interests-page" direction="col" align="center">
      <FlexContainer
        className="interests-page__header"
        align="center"
        justify="flexStart"
        gap="var(--pando-spacing-3)"
      >
        <img src={SkillsIcon} alt="Skills Icon" role="presentation" />
        <img src={SkillsText} alt="Skills Text" role="presentation" />
      </FlexContainer>
      <Show when={currentStep < 4} fallback={<FinishScreen />}>
        <Stepper className="interests-page__content" />
        <FlexContainer
          className="interests-page__footer"
          justify="center"
          align="center"
        >
          <FlexContainer justify="spaceBetween" align="center" gap="1rem">
            <Button
              aria-label={t('interests.skipForNow.ariaLabel')}
              palette="action"
              size="lg"
              usage="text"
              role="link"
              className="interests-page__skip"
              onClick={handleSkipForNow}
            >
              {t('interests.skipForNow.text')}
            </Button>
            <FlexContainer gap="2rem" className="right-btn-group">
              {currentStep > 1 && (
                <Button
                  aria-label={t('interests.back.ariaLabel')}
                  palette="action"
                  size="lg"
                  usage="text"
                  role="button"
                  className="interests-page__next"
                  startIcon={<ChevronLeftIcon />}
                  onClick={setPreviousStep}
                >
                  {t('interests.back.text')}
                </Button>
              )}
              <Button
                aria-label={
                  currentStep === 3
                    ? t('interests.done.ariaLabel')
                    : t('interests.next.ariaLabel')
                }
                disabled={!isNextButtonEnabled}
                palette="action"
                size="lg"
                usage="filled"
                role="button"
                className="interests-page__next"
                onClick={setNextStep}
              >
                {currentStep === 3
                  ? t('interests.done.text')
                  : t('interests.next.text')}
              </Button>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </Show>
    </FlexContainer>
  );
};

export default Interests;
