import {
  FlexContainer,
  FormControlProvider,
  Select,
  Option,
  FlexItem,
} from '@pluralsight/react-ng';

import type { TProficiencyLevel } from '@ps-fe-authentication/types';
import type { ChangeEvent } from 'react';

interface IProficiencyCardProps {
  interestName: string;
  proficiency?: TProficiencyLevel | null;
  onProficiencyChange: (value: TProficiencyLevel) => void;
}

const ProficiencyCard: React.FC<IProficiencyCardProps> = ({
  interestName,
  proficiency,
  onProficiencyChange,
}) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onProficiencyChange(e.target.value as TProficiencyLevel);
  };

  return (
    <FlexContainer
      className="proficiency__card"
      justify="spaceBetween"
      align="center"
      wrap="wrap"
      gap="0.5rem"
    >
      <span className="proficiency__label">{interestName}</span>
      <FlexItem className="proficiency__select" shrink="1">
        <FormControlProvider>
          <Select
            id={interestName}
            name={interestName}
            size="md"
            value={proficiency || ''}
            onChange={handleChange}
          >
            <>
              {!proficiency && (
                <Option
                  name="Select proficiency"
                  value=""
                  disabled
                  role="listbox item"
                >
                  Select proficiency
                </Option>
              )}
              <Option name="Beginner" value="Beginner" role="listbox item">
                Beginner
              </Option>
              <Option
                name="Intermediate"
                value="Intermediate"
                role="listbox item"
              >
                Intermediate
              </Option>
              <Option name="Advanced" value="Advanced" role="listbox item">
                Advanced
              </Option>
            </>
          </Select>
        </FormControlProvider>
      </FlexItem>
    </FlexContainer>
  );
};

export default ProficiencyCard;
