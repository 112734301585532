import type { LanguageOption } from '@ps-fe-authentication/types';

export const LANGUAGE_OPTIONS: LanguageOption[] = [
  {
    label: 'Deutsch',
    value: 'ge',
  },
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Español',
    value: 'es',
  },
  {
    label: 'Français',
    value: 'fr',
  },
  {
    label: '日本語',
    value: 'jp',
  },
  {
    label: 'Português',
    value: 'pt',
  },
];
