import { CheckIcon } from '@pluralsight/icons';
import { FlexContainer } from '@pluralsight/react-ng';

import './tag.scss';
import useAccessibleKeyDown from '@ps-fe-authentication/hooks/useAccessibleKeyDown';

interface ITag {
  text: string;
  onClick?: () => void;
}

const Tag = ({ text, onClick }: ITag) => {
  const handleKeyDown = useAccessibleKeyDown(onClick);

  return (
    <FlexContainer
      className="interest-tag"
      align="center"
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={handleKeyDown}
    >
      <CheckIcon />
      {text}
    </FlexContainer>
  );
};

export default Tag;
