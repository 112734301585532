import React from 'react';
import { ToastProvider } from '@pluralsight/react-ng';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { TranslationsProvider } from '@ps-fe-authentication/contexts/TranslationsContext';
import { resources } from '@ps-fe-authentication/translations';
import { UserProvider } from '@ps-fe-authentication/contexts/UserContext';
import { AppConfigProvider } from '@ps-fe-authentication/contexts/AppConfigContext';

import type { AppConfig } from '@ps-fe-authentication/contexts/AppConfigContext';
import type { UserAppData } from '@ps-fe-authentication/types';
import type { PropsWithChildren, FC } from 'react';

interface Props extends PropsWithChildren {
  initialUser: UserAppData | null;
  initialLanguage: string;
  appConfig: AppConfig | null;
}

const queryClient = new QueryClient();

const ProvidersLayer: FC<Props> = ({
  children,
  initialUser,
  initialLanguage,
  appConfig,
}) => {
  return (
    <AppConfigProvider appConfig={appConfig}>
      <QueryClientProvider client={queryClient}>
        <UserProvider initialUser={initialUser}>
          <TranslationsProvider
            resources={resources}
            initialLanguage={initialLanguage}
          >
            <ToastProvider>{children}</ToastProvider>
          </TranslationsProvider>
        </UserProvider>
      </QueryClientProvider>
    </AppConfigProvider>
  );
};

export default ProvidersLayer;
