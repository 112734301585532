// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interests .interest-tag {
  width: fit-content;
  padding: 0.125rem 0.5rem;
  gap: 0.5rem;
  border-radius: 0.375rem;
  background-color: var(--pando-inky-blue-action-background-initial-dark, #4a33d1);
  cursor: pointer;
  font-family: var(--font-pando-body), sans-serif;
  font-size: var(--pando-font-size-md);
  font-weight: 500;
}
.interests .interest-tag svg {
  flex: 0 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/components/interests/steps/interests/tag/tag.scss"],"names":[],"mappings":"AAEE;EACE,kBAAA;EACA,wBAAA;EACA,WAAA;EACA,uBAAA;EACA,gFAAA;EAIA,eAAA;EAEA,+CAAA;EACA,oCAAA;EACA,gBAAA;AALJ;AAMI;EACE,cAAA;AAJN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.interests {\n  .interest-tag {\n    width: fit-content;\n    padding: 0.125rem 0.5rem;\n    gap: 0.5rem;\n    border-radius: 0.375rem;\n    background-color: var(\n      --pando-inky-blue-action-background-initial-dark,\n      #4a33d1\n    );\n    cursor: pointer;\n\n    font-family: var(--font-pando-body), sans-serif;\n    font-size: var(--pando-font-size-md);\n    font-weight: 500;\n    svg {\n      flex: 0 0 auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
