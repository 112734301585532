import { useQuery } from '@tanstack/react-query';

import { getUserByPsId } from '@ps-fe-authentication/api/services/user-service';
import { COUNTRIES } from '@ps-fe-authentication/shared/fixtures';
import { getInvitation } from '@ps-fe-authentication/api/services/plans-service';
import { fetchInterests } from '@ps-fe-authentication/api/services/interests-service';

import type { UseQueryResult, QueriesOptions } from '@tanstack/react-query';
import type {
  Invitation,
  Plan,
  UserAccount,
  IInterest,
} from '@ps-fe-authentication/types';

type UserByPsIdQuery = (data: {
  psId: string | null;
}) => UseQueryResult<UserAccount>;

export const useUserByPsIdQuery: UserByPsIdQuery = (data) => {
  return useQuery<UserAccount>({
    queryKey: ['user', data.psId],
    queryFn: () => getUserByPsId(data.psId as string),
    enabled: Boolean(data.psId),
  });
};

export const useCountriesQuery = () => {
  return useQuery({
    queryKey: ['countries'],
    queryFn: () => COUNTRIES,
  });
};

type PlanByIdQuery = (data: { planId: string }) => UseQueryResult<Plan>;

export const usePlanByIdQuery: PlanByIdQuery = ({ planId }) => {
  return useQuery({
    queryKey: ['plan', planId],
  });
};

type InvitationQuery = (data: {
  planId: string;
  invitationId: string;
}) => UseQueryResult<Invitation>;

export const useInvitationQuery: InvitationQuery = ({
  planId,
  invitationId,
}) => {
  return useQuery({
    queryKey: ['invitation', invitationId],
    queryFn: () => getInvitation(planId, invitationId),
  });
};

export const useInterestsQuery = (options?: QueriesOptions<IInterest[]>) => {
  return useQuery<IInterest[]>({
    queryKey: ['interests'],
    queryFn: async () => {
      const data = await fetchInterests();

      return data.map(({ id, name, categoryName }, index) => ({
        name,
        categoryName,
        id: crypto?.randomUUID
          ? crypto.randomUUID()
          : `${id} + ${name} + ${index}`,
      }));
    },
    initialData: [],
    ...options,
  });
};
