// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.categories-card {
  width: 9.5rem;
  height: 9.375rem;
  padding: 1.4375rem 2rem;
  background: var(--pando-colors-neutral-surface-300, #2a2753);
  border-radius: 0.5rem;
  text-align: center;
  cursor: pointer;
}
.categories-card.selected {
  border-radius: 0.5rem;
  border: 4px solid var(---pando-inky-blue-action-text-200-dark, #8677e0);
  background: var(--pando-inky-blue-action-background-100-initial-dark, #2a2753);
}`, "",{"version":3,"sources":["webpack://./src/components/interests/steps/categories/card/card.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,gBAAA;EACA,uBAAA;EACA,4DAAA;EACA,qBAAA;EACA,kBAAA;EACA,eAAA;AAAF;AAEE;EACE,qBAAA;EACA,uEAAA;EACA,8EAAA;AAAJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.categories-card {\n  width: 9.5rem;\n  height: 9.375rem;;\n  padding: 1.4375rem 2rem;\n  background: var(--pando-colors-neutral-surface-300, #2a2753);\n  border-radius: 0.5rem;\n  text-align: center;\n  cursor: pointer;\n\n  &.selected {\n    border-radius: 0.5rem;\n    border: 4px solid var(---pando-inky-blue-action-text-200-dark, #8677e0);\n    background: var(--pando-inky-blue-action-background-100-initial-dark, #2a2753);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
