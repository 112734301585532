import { GridContainer } from '@pluralsight/react-ng';

import { useSelectedCategories } from '@ps-fe-authentication/contexts/InterestsContext';

import Card from './card/card';
import './categories.scss';
import { categories } from './constants';

const Categories = () => {
  const { selectedCategories, toggleCategory } = useSelectedCategories();

  return (
    <GridContainer className="categories-grid">
      {categories.map(({ id, icon, title }) => (
        <Card
          key={id}
          icon={icon}
          title={title}
          isSelected={selectedCategories.includes(id)}
          onClick={() => toggleCategory(id)}
        />
      ))}
    </GridContainer>
  );
};

export default Categories;
