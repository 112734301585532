export interface Country {
  id: number;
  name: string;
  value: string;
}

export interface CreateAccountState {
  error: string | null;
  countries: Country[];
  user: UserAccount | null;
  userCreated: boolean;
  loading: boolean;
  plan: Plan | null;
  invitationAccepted: boolean | null;
  step: string;
  declined: boolean | null;
  invitation: Invitation | null;
}

export interface UserAccount {
  active: boolean;
  id?: string;
  psUserId: string;
  username?: string | null;
  firstName: string | null;
  lastName: string | null;
  email?: string;
  age?: number | null;
  phoneNumber?: string | null;
  country?: string | null;
  planIds?: string[] | null;
  subscriptionId?: string | null;
  isTermsAccepted?: boolean;
  planDetails: { planId: string; roleIds: string[] }[];
}

export type CreateAccountData = Pick<
  UserAccount,
  'firstName' | 'lastName' | 'country' | 'isTermsAccepted'
>;

export interface AccountRequest {
  type: string;
  psId: string;
  userAccount: UserAccount;
}

export interface Account {
  firstName: string;
  lastName: string;
  country: string;
  acceptTerms: boolean;
}

export interface Plan {
  id: string;
  displayName: string;
}

export enum InvitationStatus {
  Accepted = 'Accepted',
  Declined = 'Declined',
  Expired = 'Expired',
  Pending = 'Pending',
}

export interface FetchInvitationRequest {
  planId: string;
  invitationId: string;
}

export interface AcceptInvitationRequest {
  planId: string;
  invitationId: string;
  invitationStatus: InvitationStatus;
}

export interface UserProfileInfo {
  psId: string;
  companyName: string | null;
  jobRole: string | null;
  nickname: string;
}

export interface UserProfileInfoResponseItem {
  companyName: string | null;
  jobRole: string | null;
  nickname: string;
}

export interface Invitation {
  id: string;
  email: string;
  status: InvitationStatus;
  note?: string;
  inviteRedemptionLink: string;
  expiresOn: Date;
  sendDate: Date;
}

export type AccountType = 'B2B' | 'B2C';
export type ProvisionRegion = 'EU' | 'US';
