import { useCallback, useMemo } from 'react';

import { useSelectedInterests } from '@ps-fe-authentication/contexts/InterestsContext';

import type { IInterest } from '@ps-fe-authentication/types';

interface IInterestCategoryListProps {
  interests: IInterest[];
}

const InterestCategoryList = ({ interests }: IInterestCategoryListProps) => {
  const { selectedInterests, toggleInterest } = useSelectedInterests();

  const selectedIds = useMemo(
    () => new Set(selectedInterests.map((interest) => interest.id)),
    [selectedInterests],
  );

  const handleKeyDown = useCallback(
    (interest: IInterest) => (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Enter' || e.key === ' ') {
        toggleInterest(interest);
        e.preventDefault();
      }
    },
    [toggleInterest],
  );

  return (
    <ul>
      {interests.map((interest) => {
        const isSelected = selectedIds.has(interest.id);

        return (
          <li
            key={interest.id}
            onClick={() => toggleInterest(interest)}
            onKeyDown={handleKeyDown(interest)}
            tabIndex={0}
            role="button"
            aria-pressed={isSelected}
            className={`${isSelected ? 'selected' : ''}`}
          >
            {interest.name}
          </li>
        );
      })}
    </ul>
  );
};

export default InterestCategoryList;
