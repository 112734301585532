// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.finish-screen {
  padding-top: 6rem;
  max-width: 25.5rem;
}
.finish-screen h1 {
  font-size: var(--pando-font-size-2xl, 1.5rem);
}`, "",{"version":3,"sources":["webpack://./src/components/interests/finish-screen/finish-screen.scss"],"names":[],"mappings":"AACA;EACE,iBAAA;EACA,kBAAA;AAAF;AAEE;EACE,6CAAA;AAAJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.finish-screen {\n  padding-top: 6rem;\n  max-width: 25.5rem;\n\n  h1 {\n    font-size: var(--pando-font-size-2xl, 1.5rem);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
