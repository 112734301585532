import { useParams } from 'react-router-dom';
import { Button, FlexContainer } from '@pluralsight/react-ng';
import { PandoPaddingLg } from '@pluralsight/design-tokens';
import { Trans } from 'react-i18next';

import { useIssue } from '@ps-fe-authentication/pages/issue-page/hooks/use-issue-content';
import WrenchIcon from '@ps-fe-authentication/assets/wrench.svg';

import type { IssueType } from '@ps-fe-authentication/constants/issue';

import './issue-page.scss';

const IssuePage = () => {
  const { type } = useParams<{ type: IssueType }>();

  const {
    heading,
    description: descriptionKey,
    actionLabel,
    actionCallback,
  } = useIssue(type);

  return (
    <FlexContainer
      direction="col"
      align="center"
      gap={PandoPaddingLg}
      className="issue-container"
    >
      <img src={WrenchIcon} alt="wrench icon" role="presentation" />
      <FlexContainer
        className="issue-content"
        gap="var(--pando-spacing-3)"
        direction="col"
        align="center"
      >
        <h1 className="issue-content__heading">{heading}</h1>
        <p className="issue-content__description">
          <Trans
            i18nKey={descriptionKey}
            components={{
              br: <br />,
              b: <b />,
            }}
          />
        </p>
      </FlexContainer>
      {actionLabel && actionCallback && (
        <Button
          aria-label={actionLabel}
          size="lg"
          usage="filled"
          role="link"
          className="issue-content__action-button"
          onClick={actionCallback}
        >
          {actionLabel}
        </Button>
      )}
    </FlexContainer>
  );
};

export default IssuePage;
