import Proficiency from '@ps-fe-authentication/components/interests/steps/proficiency/proficiency';
import CategoriesCard from '@ps-fe-authentication/components/interests/steps/categories';
import Interests from '@ps-fe-authentication/components/interests/steps/interests';

type TTranslationKey = string;

export interface IStepData {
  label: TTranslationKey;
  title: TTranslationKey;
  subtitle: TTranslationKey;
  stepContentComponent: React.FC;
}

export const steps: IStepData[] = [
  {
    label: 'interests.steps.categories.label',
    title: 'interests.steps.categories.title',
    subtitle: 'interests.steps.categories.subtitle',
    stepContentComponent: CategoriesCard,
  },
  {
    label: 'interests.steps.interests.label',
    title: 'interests.steps.interests.title',
    subtitle: 'interests.steps.interests.subtitle',
    stepContentComponent: Interests,
  },
  {
    label: 'interests.steps.proficiency.label',
    title: 'interests.steps.proficiency.title',
    subtitle: 'interests.steps.proficiency.subtitle',
    stepContentComponent: Proficiency,
  },
];
