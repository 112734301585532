import { CheckIcon, ChevronDownIcon, GlobeIcon } from '@pluralsight/icons';
import React from 'react';

import './select-language.scss';
import type { LanguageOption } from '@ps-fe-authentication/translations/interfaces';

interface SelectLanguageProps {
  languageOptions: LanguageOption[];
  changeLanguage: (selectedLanguage: string) => void;
  language: string;
}

const SelectLanguage = ({
  languageOptions,
  changeLanguage,
  language,
}: SelectLanguageProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [highlightedIndex, setHighlightedIndex] = React.useState<number>(
    languageOptions.findIndex((lang) => lang.value === language),
  );
  const [selectedLanguageText, setSelectedLanguageText] =
    React.useState<string>(
      languageOptions.find((lang) => lang.value === language)?.label || '',
    );

  const toggleMenu = () => setIsOpen(!isOpen);

  const languageLabel = () =>
    languageOptions.find((lang) => lang.value === language)?.label;

  const closeMenu = () => {
    setIsOpen(false);
    setHighlightedIndex(
      languageOptions.findIndex((lang) => lang.value === language),
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (!isOpen) return;

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        setHighlightedIndex((prev) =>
          prev < languageOptions.length - 1 ? prev + 1 : 0,
        );

        break;
      case 'ArrowUp':
        event.preventDefault();
        setHighlightedIndex((prev) =>
          prev > 0 ? prev - 1 : languageOptions.length - 1,
        );

        break;
      case 'Enter':
      case ' ':
        if (highlightedIndex >= 0) {
          event.preventDefault();

          const selectedOption = languageOptions[highlightedIndex];

          changeLanguage(selectedOption.value);
          setSelectedLanguageText(
            selectedOption.value === 'jp' ? 'Japanese' : selectedOption.label,
          );
          closeMenu();
        }

        break;
      case 'Escape':
        event.preventDefault();
        closeMenu();

        break;
    }
  };

  const handleOptionMouseDown = (index: number) => {
    const selectedOption = languageOptions[index];

    changeLanguage(selectedOption.value);
    setSelectedLanguageText(
      selectedOption.value === 'jp' ? 'Japanese' : selectedOption.label,
    );
    closeMenu();
  };

  const handleAriaLabel = () =>
    `Selected language: ${
      languageOptions.find((lang) => lang.value === language)?.value === 'jp'
        ? 'Japanese'
        : languageOptions.find((lang) => lang.value === language)?.label
    }`;

  const handleAccesibilityHighligthedOption = () => {
    if (highlightedIndex >= 0) {
      if (languageOptions[highlightedIndex].value === 'jp') {
        return 'Highlighted japanese';
      } else {
        return `Highlighted: ${languageOptions[highlightedIndex].label}`;
      }
    } else {
      return 'No language highlighted';
    }
  };

  return (
    <div className="select-container">
      <div aria-live="polite" aria-atomic="true" className="sr-only">
        {`${selectedLanguageText} selected`}
      </div>
      <button
        className={`select-button ${isOpen ? 'open' : ''}`}
        onClick={toggleMenu}
        onKeyDown={handleKeyDown}
        onBlur={closeMenu}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-controls="language-options"
        aria-label={handleAriaLabel()}
      >
        <GlobeIcon className="globe-icon" />
        <span className="language-label">{languageLabel()}</span>
        <ChevronDownIcon className="arrow-icon" />
      </button>
      {isOpen && (
        <div id="language-options" className="select-options">
          <span id="language-options-label" className="sr-only">
            Available languages:
          </span>
          {languageOptions.map((languageOption, index) => (
            <button
              id={`language-option-${languageOption.value}`}
              className={`select-option`}
              key={languageOption.value}
              aria-label={`label-${languageOption.value}`}
              role="option"
              aria-selected={language === languageOption.value}
              lang={languageOption.value}
              aria-labelledby={`label-${languageOption.value}`}
              tabIndex={highlightedIndex === index ? 0 : -1}
              onMouseDown={() => handleOptionMouseDown(index)}
              onMouseEnter={() => setHighlightedIndex(index)}
              style={{
                backgroundColor:
                  index === highlightedIndex
                    ? 'var(--pando-colors-action-border-initial)'
                    : '',
              }}
            >
              {languageOption.label}
              {highlightedIndex === index && (
                <CheckIcon className="check-icon" />
              )}
            </button>
          ))}
        </div>
      )}
      <div aria-live="assertive" aria-atomic="true" className="sr-only">
        {handleAccesibilityHighligthedOption()}
      </div>
    </div>
  );
};

export default SelectLanguage;
