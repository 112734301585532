import { Button, FlexContainer } from '@pluralsight/react-ng';
import { ChevronUpIcon, ChevronDownIcon } from '@pluralsight/icons';
import React, { useLayoutEffect, useRef, useState } from 'react';

import Tag from '@ps-fe-authentication/components/interests/steps/interests/tag';
import { useTranslations } from '@ps-fe-authentication/contexts/TranslationsContext';
import { useSelectedInterests } from '@ps-fe-authentication/contexts/InterestsContext';

const showMoreBtnStyle = { padding: '0.125rem 1rem' };

const TagList = () => {
  const { selectedInterests, toggleInterest } = useSelectedInterests();
  // Index of the last tag that fits in two rows
  const [maxVisibleIndex, setMaxVisibleIndex] = useState(
    selectedInterests.length - 1,
  );
  // Flag to trigger re-measurement (e.g., on resize)
  const [shouldMeasure, setShouldMeasure] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const prevContainerWidthRef = useRef<number>(0);

  const { t } = useTranslations();

  // We temporarily render all tags (when shouldMeasure is true or when expanded)
  // then determine how many fit in two rows based on their offsetTop.
  const calculateVisibleIndex = () => {
    if (!containerRef.current) return;

    const items = Array.from(containerRef.current.children) as HTMLElement[];

    if (!items.length) return;

    const firstLineTop = items[0].offsetTop;
    let secondLineTop: number | null = null;
    let lastVisible = items.length - 1;

    for (let i = 0; i < items.length; i++) {
      const top = items[i].offsetTop;

      if (secondLineTop === null && top > firstLineTop) {
        secondLineTop = top;
      } else if (secondLineTop !== null && top > secondLineTop) {
        lastVisible = i - 1 - 1; // Subtract an extra 1 to reserve space for the "Show More" button

        break;
      }
    }

    setMaxVisibleIndex(lastVisible);
  };

  // On initial render and when a measurement is needed, calculate the visible index.
  useLayoutEffect(() => {
    if (shouldMeasure) {
      calculateVisibleIndex();
      setShouldMeasure(false);
    }
  }, [shouldMeasure, selectedInterests, expanded]);

  // Re-measure if container width has changed (e.g., window resize)
  useLayoutEffect(() => {
    if (!containerRef.current) return;

    const ro = new ResizeObserver(() => {
      const containerWidth =
        containerRef.current?.getBoundingClientRect().width ?? 0;

      if (containerWidth !== prevContainerWidthRef.current) {
        prevContainerWidthRef.current = containerWidth;
        // Use requestAnimationFrame to avoid "ResizeObserver loop" errors
        requestAnimationFrame(() => setShouldMeasure(true));
      }
    });

    ro.observe(containerRef.current);

    return () => ro.disconnect();
  }, []);

  useLayoutEffect(() => {
    requestAnimationFrame(() => setShouldMeasure(true));
  }, [selectedInterests]);

  // Determine how many tags to show
  const visibleCount = expanded
    ? selectedInterests.length
    : maxVisibleIndex + 1;
  const hiddenCount = selectedInterests.length - visibleCount;
  // When measuring or expanded, render all tags for accurate measurement.
  const renderInterests =
    shouldMeasure || expanded
      ? selectedInterests
      : selectedInterests.slice(0, visibleCount);

  return (
    <FlexContainer ref={containerRef} gap="1rem" wrap="wrap">
      {renderInterests.map((interest) => (
        <Tag
          key={interest.id}
          text={interest.name}
          onClick={() => toggleInterest(interest)}
        />
      ))}
      {!expanded && !shouldMeasure && hiddenCount > 0 && (
        <Button
          style={showMoreBtnStyle}
          aria-label={t('interests.steps.interests.showMore.ariaLabel')}
          size="md"
          palette="action"
          usage="text"
          onClick={() => setExpanded(true)}
        >
          {`+ ${hiddenCount} ${t('interests.steps.interests.showMore.value')}`}
          <ChevronDownIcon />
        </Button>
      )}
      {expanded && (
        <Button
          style={showMoreBtnStyle}
          aria-label={t('interests.steps.interests.collapseAll.ariaLabel')}
          size="md"
          palette="action"
          usage="text"
          onClick={() => setExpanded(false)}
        >
          {t('interests.steps.interests.collapseAll.value')}
          <ChevronUpIcon />
        </Button>
      )}
    </FlexContainer>
  );
};

export default TagList;
